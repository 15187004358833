import {PaletteOptions} from '@mui/material';
import {green, blue, orange, blueGrey, grey} from '@mui/material/colors';

const white = '#FFFFFF';
const black = '#000000';
const customPalette: PaletteOptions = {
  primary: {
    main: '#8236FF',
    light: '#00c2a2',
  },
  grey: {
    '200': '#E5E5E5',
    '600': '#4D4D4D',
    '700': '#333333',
  },
  secondary: {
    main: '#00C2A2',
    light: '#00CCAB',
  },
  success: {
    dark: green[900],
    main: green[600],
    light: green[400],
  },
  info: {
    dark: blue[900],
    main: blue[600],
    light: blue[400],
    contrastText: '#0099FF',
  },
  warning: {
    dark: orange[900],
    main: orange[600],
    light: orange[400],
  },
  error: {
    main: '#E54C4C',
  },
  text: {
    primary: blueGrey[900],
    secondary: '#b3b3b3',
  },
  typography: {
    headingDark: '#212121',
    headingLight: '#616161',
    infoLight: '#9E9E9E',
  },
  background: {
    default: '#1A1A1A',
    paper: '#0D0D0D',
  },
  divider: grey[900],
  defaults: {
    white: white,
    black: black,
  },
  gradients: {
    main: 'linear-gradient(0deg, #000000, #000000), #FFFFFF',
    secondary: 'linear-gradient(180deg, #242534 0%, #17171E 47.98%, #141418 100%)',
  },
};

export default customPalette;

import {OutlinedInputClasses, Theme} from '@mui/material';
import {OverridesStyleRules} from '@mui/material/styles/overrides';

export default {
  styleOverrides: {
    root: {
      borderRadius: '20px',
    },
    notchedOutline: {
      border: '2px solid #444',
    },
  } as Partial<
    OverridesStyleRules<keyof OutlinedInputClasses, 'MuiOutlinedInput', Omit<Theme, 'components'>>
  >,
};

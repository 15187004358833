import {PaperClasses, Theme} from '@mui/material';
import {OverridesStyleRules} from '@mui/material/styles/overrides';

export default {
  styleOverrides: {
    root:({theme}) => ({
      background: theme.palette.background.default
    }),
    elevation8: ({theme}) => ({
      boxShadow: '0px 4px 10px rgba(0, 0, 0, .3)',
    }),
  } as Partial<OverridesStyleRules<keyof PaperClasses, 'MuiPaper', Omit<Theme, 'components'>>>,
};

import { User } from '../types/User';
import { UserActionType } from '../types/actions';

const userReducerDefaultState: User = {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    isAdmin: false,
    isUser: false,
    isLoopProvider: false,
    status: ''
};

const userReducer = (state = userReducerDefaultState, action: UserActionType): User => {
    switch (action.type) {
        case 'SET_USER':
            return { ...state, ...action.user };
        case 'CLEAR_USER':
            return {
                ...userReducerDefaultState
            };
        default:
            return state;
    }
};
export { userReducer };

export default {
  styleOverrides: {
    root: {
      backgroundColor: 'rgba(128, 128, 128, .3)',
    },
    wave: {
      '&::after': {
        backgroundColor: 'rgb(187 187 187 / 0.50);',
      },
    },
  },
};

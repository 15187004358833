import axios from 'axios';

export async function matchWithSndlkId(
  id: string,
  instruments: string[],
  genre: string[]
) {
  try {
    const allInst = instruments.join(',');
    const allGenre = genre.join(',');
    const response = await axios.post(`${process.env.NX_LoopAPI}/getRecommendationsSndlk`, {
      sndlkId: id,
      'feature-name': 'harmony',
      limit: 50,
      ...(instruments.length > 0 && { Instrument: allInst }),
      ...(genre.length > 0 && { Genre: allGenre }),
    });
    return response?.data?.Matches;
  } catch (error) {
    return null;
  }
}

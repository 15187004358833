export const HashHmacSha256 = async ({
  data,
  pass,
}: {
  data: string;
  pass: string;
}) => {
  const enc = new TextEncoder();
  const algorithm = { name: 'HMAC', hash: 'SHA-256' };

  const key = await crypto.subtle.importKey(
    'raw',
    enc.encode(pass),
    algorithm,
    false,
    ['sign', 'verify']
  );
  const signature = await crypto.subtle.sign(
    algorithm.name,
    key,
    enc.encode(data)
  );
  const hex = [...new Uint8Array(signature)]
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  //   const digest = btoa(String.fromCharCode(...new Uint8Array(signature)));

  return hex;
};

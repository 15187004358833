import { AdminUserActionType, SET_ADMIN_AFFILIATE_HISTORY_DATA } from '../types/actions';
import { AdminAffiliateHistoryDataArray } from './../types/adminAffiliateHistory';

const affiliateHistoryState: AdminAffiliateHistoryDataArray = {
    data: [],
    loading: true
};

const adminAffiliateHistoryReducer = (
    state = affiliateHistoryState,
    action: AdminUserActionType
): AdminAffiliateHistoryDataArray => {
    switch (action.type) {
        case SET_ADMIN_AFFILIATE_HISTORY_DATA:
            return { ...state, data: [...action.data], loading: false };
        default:
            return state;
    }
};

export { adminAffiliateHistoryReducer };

import { AdminUserDataArray, AdminUserData } from '../types/admin';
import { AdminUserActionType } from '../types/actions';
import { SET_USER_DATA, CHANGE_USER_STATUS, DELETE_USER } from '../types/actions';

const adminUserlistState: AdminUserDataArray = {
    data: []
};

const adminReducer = (
    state = adminUserlistState,
    action: AdminUserActionType
): AdminUserDataArray => {
    switch (action.type) {
        case SET_USER_DATA:
            return { ...state, data: [...action.data] };
        case CHANGE_USER_STATUS: {
            const { id, status } = action.payload;
            const changedData: AdminUserData[] = state.data.map((item: AdminUserData) => {
                if (item._id === id) {
                    item.status = status;
                }
                return item;
            });
            return {
                ...state,
                data: [...changedData]
            };
        }
        case DELETE_USER: {
            const userIds = action.payload;
            const deletedItems = state.data.filter((item: AdminUserData) => {
                return userIds.indexOf(item.cognitoUserId) === -1 ? true : false;
            });
            return {
                ...state,
                data: [...deletedItems]
            };
        }
        default:
            return state;
    }
};
export { adminReducer };

import { MatchHistoryDataArray } from '../types/matchHistory';
import { HistoryActionType } from '../types/actions';
import { SET_MATCH_HISTORY_DATA } from '../types/actions';

const matchHistoryState: MatchHistoryDataArray = {
    data: [],
    loading: true
};

const matchHistoryReducer = (
    state = matchHistoryState,
    action: HistoryActionType
): MatchHistoryDataArray => {
    switch (action.type) {
        case SET_MATCH_HISTORY_DATA:
            return { ...state, data: [...action.data], loading: false };
        default:
            return state;
    }
};

export { matchHistoryReducer };

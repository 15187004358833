import { AdminPurchaseHistoryDataArray } from '../types/adminPurchaseHistory';
import { AdminUserActionType } from '../types/actions';
import { SET_ADMIN_PURCHASE_HISTORY_DATA } from '../types/actions';

const purchaseHistoryState: AdminPurchaseHistoryDataArray = {
    data: [],
    loading: true
};

const adminPurchaseHistoryReducer = (
    state = purchaseHistoryState,
    action: AdminUserActionType
): AdminPurchaseHistoryDataArray => {
    switch (action.type) {
        case SET_ADMIN_PURCHASE_HISTORY_DATA:
            return { ...state, data: [...action.data], loading: false };
        default:
            return state;
    }
};

export { adminPurchaseHistoryReducer };

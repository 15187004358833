import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store';
import { saveState } from './utils/LocalStorage';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { SnackbarProvider } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import { BrowserRouter as Router } from 'react-router-dom';

const promise = loadStripe(process.env.NX_STRIPE_KEY);

store.subscribe(() => {
  saveState({
    user: store.getState().user,
    cart: store.getState().cart,
    favourites: store.getState().favourites,
    profile: store.getState().profile,
  });
});

const notistackRef = React.createRef<any>();
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <Elements stripe={promise}>
          <SnackbarProvider
            classes={{
              variantWarning: 'snackbarWarning',
            }}
            ref={notistackRef}
            action={(key) => (
              <CancelIcon
                onClick={() => {
                  notistackRef.current.closeSnackbar(key);
                }}
                style={{
                  cursor: 'pointer',
                }}
              />
            )}
          >
            <App />
          </SnackbarProvider>
        </Elements>
      </Provider>
    </Router>
  </React.StrictMode>
);

import { AffiliateHistoryType, SET_AFFILIATE_HISTORY_DATA } from '../types/actions';
import { AffiliateHistoryDataArray } from '../types/AffiliateHistory';

const affiliateHistoryState: AffiliateHistoryDataArray = {
    data: [],
    loading: true
};

const affiliateHistoryReducer = (
    state = affiliateHistoryState,
    action: AffiliateHistoryType
): AffiliateHistoryDataArray => {
    switch (action.type) {
        case SET_AFFILIATE_HISTORY_DATA:
            return { ...state, data: [...action.data], loading: false };
        default:
            return state;
    }
};

export { affiliateHistoryReducer };

import breakpoints from './breakpoints';
import palette from './palette';
import components from './components';
import {getTypography} from './typography';
import {Theme, ThemeOptions, createTheme} from '@mui/material/styles';

export const createBaseTheme = (theme: Theme): ThemeOptions => {
  return createTheme({
    palette,
    breakpoints,
    components,
    // components:{MuiTablePagination:{
    //   styleOverrides:{}
    // }},
    typography: getTypography,
    fonts: {
      main: 'Plus Jakarta Sans',
      secondary: 'Plus Jakarta Sans',
    },
  });
};

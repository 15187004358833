import {
    ADD_FAVOURITES_DATA,
    FavouritesActionType,
    FAVOURITE_DATA_LOADING_TOGGLE,
    SET_FAVOURITES_DATA
} from '../types/actions';
import { Dispatch } from 'redux';
import { API } from '../../config/config';
import { FavouriteItem } from '../types/Favourites';
import { loopAPI } from './../../config/config';
import { REMOVE_ONE_FAVOURITE_DATA } from './../types/actions';

const setData = (data: FavouriteItem[]): FavouritesActionType => ({
    data,
    type: SET_FAVOURITES_DATA
});

const addFavourite = (data: FavouriteItem): FavouritesActionType => ({
    data,
    type: ADD_FAVOURITES_DATA
});

const removeOne = (LoopId: string): FavouritesActionType => ({
    LoopId,
    type: REMOVE_ONE_FAVOURITE_DATA
});

// export const setFavouriteData =
//     (data: FavouriteItem) => (dispatch: Dispatch<FavouritesActionType>) => {
//         dispatch(setData(data));
//     };

// Get Favourite from backend
export const GetFavouriteFromBackend =
    (perPage, pageNo) => (dispatch: Dispatch<FavouritesActionType>) => {
        dispatch({ loading: true, type: FAVOURITE_DATA_LOADING_TOGGLE });
        API.get(`${loopAPI}/favourites?perPage=${perPage}&pageNo=${pageNo}`).then((res) => {
            dispatch(setData(res.data.data))
        });
    };

// update Favourite to backend
export const UpdateFavouriteToBackend =
    (data: FavouriteItem, enqueueSnackbar: any) => (dispatch: Dispatch<FavouritesActionType>) => {
        API.post(`${loopAPI}/favourites`, { loopId: data.LoopId })
            .then((res) => {
                dispatch(addFavourite(data))
                enqueueSnackbar(`${data.TrackTitle} added to Favourite`, {
                    autoHideDuration: 3000,
                    variant: 'success'
                });
            })
            .catch((err) => {
                // console.log(err?.response?.data);
                const errMessage = err?.response?.data?.message || err?.response?.data;
                enqueueSnackbar(errMessage, {
                    autoHideDuration: 7000,
                    variant: 'error'
                });
            });
    };

// Get Favourite from backend
export const DeleteFavouriteFromBackend =
    (LoopId, perPage = 10, currentPage = 1) =>
    (dispatch: Dispatch<FavouritesActionType>) => {
        API.delete(`${loopAPI}/favourites/${LoopId}`).then((res) => {
            dispatch(removeOne(LoopId))
            API.get(`${loopAPI}/favourites?perPage=${perPage}&pageNo=${currentPage}`).then(
                (res) => {
                    dispatch(setData(res.data.data))
                }
            );
        });
    };

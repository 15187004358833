import axios, {AxiosRequestConfig} from 'axios';
import {Auth, Amplify} from 'aws-amplify';
import awsconfig, {awsmobileProd} from './../aws-exports';
import {HashHmacSha256} from '../utils/HmacSHA256';

// Const
export const loopAPI = process.env.NX_LoopAPI;
export const oneShotBase = process.env.NX_OneShotBase;
export const oneShotToken = process.env.NX_OneShotToken;

const devHostNames = [
  'dev.vst.jamahook.com',
  'localhost',
  'stg.vst.jamahook.com',
  //   'beta.vst.jamahook.com',
];

// CONFIG AMPLIFY -->
export const configureAmplify = (): void => {
  if (devHostNames.includes(window.location.hostname)) {
    Amplify.configure(awsconfig);
  } else {
    Amplify.configure(awsmobileProd);
  }
};

// CONFIG AXIOS -->;
export const baseUrl = process.env.NX_WEB_BASE_URL;

export const API = axios.create({
  baseURL: process.env.NX_WEB_BASE_URL,
});

// Guest API Axios
export const GUEST_API = axios.create({
  baseURL: loopAPI,
});

GUEST_API.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('guestToken');

    if (token) {
      config.headers = {
        ...config.headers,
        token,
      };
    }
    return config;
  },
  error => Promise.reject(error)
);
GUEST_API.interceptors.response.use(
  response => response,
  async error => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const result = await GUEST_API.post('/auth/getNonce', {
        user: process.env.NX_GUEST_USER,
      });

      if (result?.data?.nonce) {
        const hmacKey = await HashHmacSha256({
          data: result.data.nonce,
          pass: process.env.NX_GUEST_USER_PASS,
        });

        const formData = new FormData();
        formData.append('user', process.env.NX_GUEST_USER);
        formData.append('nonce', result.data._id);
        formData.append('hash', hmacKey);
        const tokens = await GUEST_API.post('/auth/login', formData);
        if (tokens?.data?.token) {
          localStorage.setItem('guestToken', tokens?.data?.token);
          config.headers = {
            ...config.headers,
            token: tokens?.data?.token,
          };
        }
      }

      return axios(config);
    }
    return Promise.reject(error);
  }
);

//Guest API End

// GET TOKEN
export const getToken = async () => {
  const session = await Auth.currentSession();
  return session.getIdToken()?.getJwtToken();
};

// GET All Session Token
export const getSessionToken = async () => {
  const session = await Auth.currentSession();
  const tokens = {
    accessToken: session.getAccessToken()?.getJwtToken(),
    refreshToken: session.getRefreshToken()?.getToken(),
    idToken: session.getIdToken()?.getJwtToken(),
  };
  return tokens;
};

export const setjwtToken = async () => {
  API.interceptors.request.use(async (config: AxiosRequestConfig) => {
    try {
      // await Auth.currentAuthenticatedUser();
      const session = await Auth.currentSession();
      config.headers.token = session?.getIdToken()?.getJwtToken();
      return await Promise.resolve(config);
    } catch (_) {
      localStorage.setItem('State', '');
      config.headers.token = '';
      return await Promise.resolve(config);
    }
  });
  API.interceptors.response.use(
    response => {
      return response;
    },
    async function (error) {
      console.error(error); //eslint-disable-line no-console
      if (
        error.response?.status === 403 ||
        error.response?.status === 401 ||
        error.response?.data === 'Token Required'
      ) {
        localStorage.setItem('State', '');
        window.location.href = '/';
      }
      return Promise.reject(error);
    }
  );
};

export const config = (): void => {
  setjwtToken();
};

export default {
  styleOverrides: {
    root: {
      color: '#B3B3B3',
      '&.Mui-checked':{
        color: '#8236FF'
      }
    },
  },
};

import {
  SET_CART_DATA,
  UPDATE_CART_DATA,
  TOGGLE_CART_LOADING,
  CartActionType,
  PURCHASE_CART_ITEMS,
  CART_REMOVE_ITEM,
  FILTER_PURCHASED_CART_ITEMS,
} from '../types/actions';
import {CartItemArray} from '../types/cart';
import {PurchaseHistoryData} from '../types/purchaseHistory';

const initialState: CartItemArray = {
  data: [],
  receipt: {},
  loading: true,
};

const cartReducer = (state = initialState, action: CartActionType): CartItemArray => {
  switch (action.type) {
    case SET_CART_DATA:
      return {
        ...state,
        data: [...state.data, action.data],
        loading: false,
      };
    case UPDATE_CART_DATA:
      return {
        ...state,
        data: [...action.data],
        loading: false,
      };
    case PURCHASE_CART_ITEMS:
      return {
        ...state,
        receipt: action.data,
        loading: false,
      };
    case TOGGLE_CART_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case CART_REMOVE_ITEM: {
      const filtered = state.data.filter(item => item._id !== action.id);
      return {
        ...state,
        data: [...filtered],
      };
    }
    case FILTER_PURCHASED_CART_ITEMS: {
      const flagged = state.data.map(item => {
        if (action.data.findIndex((find: PurchaseHistoryData) => item._id === find.loopId) !== -1) {
          return {
            ...item,
            matched: false,
            message: 'Already purchased',
          };
        }
        return item;
      });
      return {
        ...state,
        data: [...flagged],
        loading: false,
      };
    }
    default:
      return state;
  }
};
export {cartReducer};

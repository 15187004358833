import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import { userReducer } from './reducers/userReducer';
import { notifiactionReducer } from './reducers/notificationReducer';
import { adminReducer } from './reducers/adminReducer';
import { profileReducer } from './reducers/ProfileReducer';
import thunk from 'redux-thunk';
import { loadState } from '../utils/LocalStorage';
import { salesHistoryReducer } from './reducers/salesHistoryReducer';
import { purchaseHistoryReducer } from './reducers/purchaseHistoryReducer';
import { cartReducer } from './reducers/cartReducer';
import { subscriptionReducer } from './reducers/subscriptionReducer';
import { subscriptionHistoryReducer } from './reducers/subscriptionHistoryReducer';
import { adminPurchaseHistoryReducer } from './reducers/adminPurchaseHistoryReducer';
import { adminSubscriptionHistoryReducer } from './reducers/adminSubscriptionHistoryReducer';
import { soundsReducer } from './reducers/soundsReducer';
import { FavouriteReducer } from './reducers/FavouritesReducer';
import { adminAffiliateHistoryReducer } from './reducers/adminAffiliateHistoryReducer';
import { adminAffiliateUsersReducer } from './reducers/adminAffiliateUsersReducer';
import { affiliateHistoryReducer } from './reducers/affiliateHistoryReducer';
import { affiliatePayoutHistoryReducer } from './reducers/affiliatePayoutHistoryReducer';
import { adminAffiliatePayoutHistoryReducer } from './reducers/adminAffiliatePayoutReducer';
import { matchHistoryReducer } from './reducers/matchHistoryReducer';
import { dragOutHistoryReducer } from './reducers/dragOutHistoryReducer';
import { errorMessageReducer } from './reducers/errorMessageReducer';

const persistedState = loadState();

export const rootReducer = combineReducers({
    user: userReducer,
    notification: notifiactionReducer,
    errorMessage: errorMessageReducer,
    userList: adminReducer,
    profile: profileReducer,
    salesHistory: salesHistoryReducer,
    purchaseHistory: purchaseHistoryReducer,
    matchHistory: matchHistoryReducer,
    dragOutHistory: dragOutHistoryReducer,
    subscriptionHistory: subscriptionHistoryReducer,
    affiliateHistory: affiliateHistoryReducer,
    affiliatePayoutHistory: affiliatePayoutHistoryReducer,
    cart: cartReducer,
    favourites: FavouriteReducer,
    sounds: soundsReducer,
    packages: subscriptionReducer,
    adminPurchaseHistory: adminPurchaseHistoryReducer,
    adminSubscriptionHistory: adminSubscriptionHistoryReducer,
    adminAffiliateHistory: adminAffiliateHistoryReducer,
    adminAffiliatePayoutHistory: adminAffiliatePayoutHistoryReducer,
    adminAffiliateUsers: adminAffiliateUsersReducer
});

export type AppState = ReturnType<typeof rootReducer>;

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    rootReducer,
    persistedState,
    compose(applyMiddleware(thunk), composeEnhancers())
);

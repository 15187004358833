import palette from "../palette";

export default {
  styleOverrides: {
    paper: {
      background: '#333',
      color: palette.defaults.white,
      borderRadius: '15px'
    },
    popper: {
      inset: '5px auto auto 0px'
    }
  },
};

import {
    AdminSubscriptionHistoryData,
    AdminSubscriptionHistoryDataArray
} from '../types/adminSubscriptionHistory';
import { AdminUserActionType } from '../types/actions';
import { SET_ADMIN_SUBSCRIPTION_HISTORY_DATA } from '../types/actions';

const subscriptionHistoryState: AdminSubscriptionHistoryDataArray = {
    data: [],
    loading: true
};

const adminSubscriptionHistoryReducer = (
    state = subscriptionHistoryState,
    action: AdminUserActionType
): AdminSubscriptionHistoryDataArray => {
    switch (action.type) {
        case SET_ADMIN_SUBSCRIPTION_HISTORY_DATA:
            return { ...state, data: [...action.data], loading: false };
        default:
            return state;
    }
};

export { adminSubscriptionHistoryReducer };

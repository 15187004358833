import { useRef, useState } from 'react';
import * as Tone from 'tone';
import { isSafari } from 'react-device-detect';

const loopAPI = process.env.NX_LoopAPI;

interface TPlayProps {
  event: any;
  url: string;
  id: string;
  rbpm?: number;
  tbpm?: number;
  shift?: number;
}
export const TonePlayerHook = () => {
  const [playing, setPlaying] = useState(false);
  const [playingFrom, setPlayingFrom] = useState('');
  const [playingIndex, setPlayingIndex] = useState('');
  const [playerWaiting, setPlayerWaiting] = useState(false);

  const tonePlayer = new Tone.Player({ onstop: () => handleEnd() });
  const playerRef = useRef(tonePlayer);

  const handleToneLoopPlay = async ({
    event,
    url,
    id,
    rbpm,
    tbpm,
    shift,
  }: TPlayProps) => {
    event.stopPropagation();

    //Reset previous player state
    playerRef.current.stop();
    //Set player state and play
    setPlayingIndex(id);
    setPlayerWaiting(true);
    await playerRef.current.load(url);
    if (shift) {
      const shifted = new Tone.PitchShift(shift).toDestination();
      playerRef.current.connect(shifted);
    } else {
      playerRef.current.toDestination();
    }
    if (rbpm && tbpm) {
      playerRef.current.playbackRate = rbpm / tbpm;
    }
    setPlaying(true);
    setPlayerWaiting(false);
    playerRef.current.start();
  };
  // Loop Player --> Play
  /*
  rbpm = BPM of Reference song (matching with)
  tbpm = BPM of Targeted song (matching results)
  */
  const handlePlay = (
    event: any,
    id: string,
    playingFrom = '',
    rbpm?: number,
    tbpm?: number,
    shift?: number
  ) => {
    Tone.start().then(() => {
      const url = `${loopAPI}/getAudio?loopId=${id}${
        isSafari ? `&callFrom=apple` : ``
      }`;
      setPlayingFrom(playingFrom);
      handleToneLoopPlay({
        event,
        url,
        id,
        rbpm,
        tbpm,
        shift,
      });
    });
  };
  // Loop Player --> Pause
  const handlePause = (e: any) => {
    e?.stopPropagation?.();
    playerRef.current.stop();
    playerRef.current.disconnect();
    setPlaying(false);
  };
  const handleEnd = () => {
    setPlaying(false);
    playerRef.current.disconnect();
    // setPlayingIndex('');
  };
  return [
    playing,
    playingFrom,
    playingIndex,
    playerWaiting,
    handlePlay,
    handlePause,
  ] as const;
};

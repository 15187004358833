import {
    AppActions,
    SET_AFFILIATE_HISTORY_DATA,
    SET_AFFILIATE_PAYOUT_DATA
} from '../types/actions';
import { Dispatch } from 'redux';
import { API } from '../../config/config';
import { SalesHistoryData } from './../types/salesHistory';
import { SET_SALES_HISTORY_DATA, ERROR_SALES_HISTORY_DATA } from '../types/actions';
import { FAILED_STATUS } from '../types/Notification';
import { AffiliateHistoryData } from '../types/AffiliateHistory';
import { AffiliatePayoutHistoryData } from './../types/affiliatePayoutHistory';

export const setSalesHistoryData = (data: SalesHistoryData[]): AppActions => ({
    data,
    type: SET_SALES_HISTORY_DATA
});
export const errorSalesHistory = (): AppActions => ({
    type: ERROR_SALES_HISTORY_DATA
});
export const setNotification = (message: string, status: string): AppActions => ({
    payload: {
        message,
        status
    },
    type: 'SHOW_NOTIFICATION'
});

export const getSalesHistory = () => (dispatch: Dispatch<AppActions>): void => {
    API.get('/sales')
        .then((res) => {
            if (res.data.statusCode === 200) {
                dispatch(setSalesHistoryData(res.data.body.data))
            }
        })
        .catch((err) => {
            dispatch(setNotification(err.message, FAILED_STATUS))
            dispatch(errorSalesHistory())
        });
};
// GET AffiliateHistory Data
const setAffiliateHistoryData = (data: AffiliateHistoryData[]): AppActions => ({
    data,
    type: SET_AFFILIATE_HISTORY_DATA
});

export const getAffiliateHistoryData = () => (dispatch: Dispatch<AppActions>): void => {
    API.get('/affiliate/ownHistory')
        .then((res) => {
            dispatch(setAffiliateHistoryData(res.data.body.data))
        })
        .catch((err) => {
            console.log(err);
        });
};
const setAffiliatePayoutHistoryData = (data: AffiliatePayoutHistoryData[]): AppActions => ({
    data,
    type: SET_AFFILIATE_PAYOUT_DATA
});

export const getAffiliatePayoutHistoryData = () => (dispatch: Dispatch<AppActions>): void => {
    API.get(`/affiliate-payment/payoutHistory`)
        .then((res) => {
            dispatch(setAffiliatePayoutHistoryData(res.data.body.data))
            console.log(res);
        })
        .catch((err) => {
            console.log(err);
        });
};

import { SET_PACKAGES, SET_USER_VAT, SET_VAT_LOADING, SubscriptionsType } from '../types/actions';
import { PackagesState } from '../types/Packages';

const initialState: PackagesState = {
    data: [],
    vat: 0,
    stripeTaxId: '',
    taxLoad: false,
    vatCountry: ''
};

const subscriptionReducer = (state = initialState, action: SubscriptionsType): PackagesState => {
    switch (action.type) {
        case SET_PACKAGES:
            return {
                ...state,
                data: [...action.payload]
            };
        case SET_USER_VAT:
            return {
                ...state,
                vat: action.payload.vat,
                stripeTaxId: action.payload.stripeTaxId,
                vatCountry: action.payload.vatCountry,
                taxLoad: true
            };
        case SET_VAT_LOADING:
            return {
                ...state,
                taxLoad: action.payload
            };

        default:
            return state;
    }
};
export { subscriptionReducer };

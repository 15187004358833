import { ErrorMessageType, PROGRESS_TOGGLE } from '../types/actions';
import { ErrorMessage } from '../types/ErrorMessage';

const errorMessageReducerState: ErrorMessage = {
    message: '',
};

const errorMessageReducer = (
    state = errorMessageReducerState,
    action: ErrorMessageType
): ErrorMessage => {
    switch (action.type) {
        case 'SHOW_ERROR_MESSAGE':
            // console.log(action.payload);
            return { ...state, ...action.payload };
        // case 'PROGRESS_TOGGLE':
            // return { ...state, progress: action.payload };
        default:
            return state;
    }
};
export { errorMessageReducer };

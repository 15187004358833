import axios from 'axios';

export interface TFilterSounds {
  instruments?: string[];
  genre?: string[];
  apiUrl: string;
}

export const submitFilterData = async <T>({
  instruments,
  genre,
  apiUrl,
}: TFilterSounds): Promise<T | null> => {
  const params: any = { 'feature-name': 'harmony' };
  if (instruments && instruments.length > 0)
    params.Instrument = instruments.join(',');
  if (genre && genre.length > 0) params.Genre = genre.join(',');
  // Url encoded data
  const data = Object.entries(params)
    .map(([key, val]: any) => `${key}=${encodeURIComponent(val)}`)
    .join('&');

  try {
    const filteredLoop = await axios.post(apiUrl, data, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    });
    return filteredLoop.data;
  } catch (error) {
    // console.log(error);
  }
  return null;
};

import { PurchaseHistoryDataArray } from '../types/purchaseHistory';
import { HistoryActionType } from '../types/actions';
import { SET_PURCHASE_HISTORY_DATA, ERROR_PURCHASE_HISTORY_DATA } from '../types/actions';

const purchaseHistoryState: PurchaseHistoryDataArray = {
    data: [],
    loading: true,
    title: 'Purchased Loops'
};

const purchaseHistoryReducer = (
    state = purchaseHistoryState,
    action: HistoryActionType
): PurchaseHistoryDataArray => {
    switch (action.type) {
        case SET_PURCHASE_HISTORY_DATA:
            return { ...state, data: [...action.data], loading: false };
        case ERROR_PURCHASE_HISTORY_DATA:
            return { ...state, data: [], loading: false };
        default:
            return state;
    }
};

export { purchaseHistoryReducer };

export default {
  styleOverrides: {
    root: {},
    underline: {
      '&:before': {
        borderBottom: '1px solid rgba(158, 158, 158, .5)',
      },
      '&:after': {
        borderBottom: '1px solid rgba(158, 158, 158, 1)',
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: '1px solid rgba(158, 158, 158, 1)',
      },
    },
  },
};

import {TableCellClasses, Theme} from '@mui/material';
import {OverridesStyleRules} from '@mui/material/styles/overrides';

export default {
  styleOverrides: {
    root: {
      fontSize: '1rem',
      borderBottom: '1px solid #63636342',
      padding: '1rem 1rem 1rem 0rem',
    },
    body: ({theme}) => ({
      color: theme.palette.text.secondary,
    }),
  } as Partial<
    OverridesStyleRules<keyof TableCellClasses, 'MuiTableCell', Omit<Theme, 'components'>>
  >,
};

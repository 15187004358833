import { SalesHistoryDataArray } from '../types/salesHistory';
import { HistoryActionType } from '../types/actions';
import { SET_SALES_HISTORY_DATA, ERROR_SALES_HISTORY_DATA } from '../types/actions';

const salesHistoryState: SalesHistoryDataArray = {
    data: [],
    loading: true
};

const salesHistoryReducer = (
    state = salesHistoryState,
    action: HistoryActionType
): SalesHistoryDataArray => {
    switch (action.type) {
        case SET_SALES_HISTORY_DATA:
            return { ...state, data: [...action.data], loading: false };
        case ERROR_SALES_HISTORY_DATA:
            return { ...state, data: [], loading: false };
        default:
            return state;
    }
};

export { salesHistoryReducer };

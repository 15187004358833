import { createContext } from 'react';

type LocalPlayerContextType = {
  LocalPlaying: boolean;
  LocalPlayingFrom: string;
  LocalHandlePlay: (file: any, event?: any, playingFrom?: string) => void;
  LocalHandlePause: (event?: any) => void;
};

export const LocalPlayerContext =
  createContext<Partial<LocalPlayerContextType | null>>(null);

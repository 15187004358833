import React, { useState, useEffect, ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store';
import { Location, Navigate } from 'react-router-dom';
import { NonAuthRoutesEnum } from './RouteConst';
import { Auth } from 'aws-amplify';
import { ClearAllLocalData } from './../utils/ClearAllLocalData';

interface Props {
  Component: React.FC;
  requiredRoles: string[];
  location: Location;
}

const PrivateRoute = ({
  Component,
  requiredRoles = [],
  location,
}: Props): JSX.Element => {
  const dispatch = useDispatch<any>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const userState = useSelector((state: AppState) => state.user);
  const userRole: string = userState.role;
  const userStatus: string = userState.status;
  const userHasRequiredRole: boolean = requiredRoles.includes(userRole);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setIsAuthenticated(!!user);
      })
      .catch((_) => {
        ClearAllLocalData(dispatch);
      });
  }, [userRole]);

  const message = userHasRequiredRole
    ? 'Please login to view this page'
    : "You can't be here!";

  let returnComponent: ReactElement;
  if (isAuthenticated && userHasRequiredRole && userStatus === 'activated') {
    returnComponent = <Component />;
  } else if (userStatus === 'deactivated') {
    returnComponent = (
      <Navigate
        to={{
          pathname: NonAuthRoutesEnum.deactivated,
        }}
      />
    );
  } else if (!userHasRequiredRole) {
    returnComponent = (
      <Navigate
        to={{
          pathname: userRole
            ? NonAuthRoutesEnum.unauthorised
            : NonAuthRoutesEnum.login,
        }}
        state={{
          message,
          from: location.pathname,
        }}
      />
    );
  }

  return returnComponent;
};
export default PrivateRoute;

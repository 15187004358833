import axios from 'axios';
import { useRef, useState, useEffect } from 'react';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ogv = require('ogv');
const loopAPI = process.env.NX_LoopAPI;

interface TPlayProps {
    event: any;
    url: string;
    id: string;
    setState: {
        setPlayingIndex: any;
        setPlayerWaiting: any;
        setPlaying: any;
        handleEnd: any;
    };
}
export const OgvPlayerHook = () => {
    const [playing, setPlaying] = useState(false);
    const [playingFrom, setPlayingFrom] = useState('');
    const [playingIndex, setPlayingIndex] = useState('');
    const [playerWaiting, setPlayerWaiting] = useState(false);

    //Ogv Player
    ogv.OGVLoader.base = '/assets/ogv';
    const OgvPlayer = new ogv.OGVPlayer({
        debug: true
    });
    const ogvref = useRef(OgvPlayer);

    useEffect(() => {
        ogvref.current.addEventListener('play', function () {
            setPlayerWaiting(false);
            setPlaying(true);
        });
    }, []);

    const handleOgvPlay = (url: string, handleEnd: any) => {
        ogvref.current.src = url;
        ogvref.current.play();
        ogvref.current.addEventListener('ended', handleEnd);
    };

    const handleOgvLoopPlay = ({ event, url, id, setState }: TPlayProps) => {
        event.stopPropagation();
        handlePause();
        const { setPlayerWaiting, setPlaying, setPlayingIndex, handleEnd } = setState;
        //temp
        setPlayingIndex(id);
        setPlayerWaiting(true);
        axios
            .get(url, {
                responseType: 'blob'
            })
            .then((res) => {
                if (res.status === 200) {
                    handleOgvPlay(URL.createObjectURL(res.data), handleEnd);
                }
            })
            .catch(() => {
                setPlayerWaiting(false);
                setPlaying(false);
            });
    };
    // Loop Player --> Play
    const handlePlay = (event: any, id: string, playingFrom = '') => {
        const url = `${loopAPI}/getAudio?loopId=${id}`;
        setPlayingFrom(playingFrom);
        handleOgvLoopPlay({
            event,
            url,
            id,
            setState: {
                handleEnd,
                setPlayerWaiting,
                setPlaying,
                setPlayingIndex
            }
        });
    };
    // Loop Player --> Pause
    const handlePause = (e = null) => {
        e?.stopPropagation?.();
        ogvref.current.pause();
        setPlaying(false);
    };
    const handleEnd = () => {
        setPlaying(false);
        setPlayingIndex('');
    };
    return [playing, playingFrom, playingIndex, playerWaiting, handlePlay, handlePause] as const;
};

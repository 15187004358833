export enum AuthRoutesEnum {
  dashboard = '/users',
  profile = '/user_profile',
  loopMasterEdit = '/loop_provider/edit',
  subscription = '/subscriptions',
  subscribed = '/subscribed',
  subscriptionConfirm = '/subscriptions_confirm',
  salesHistory = '/sales_history',
  purchaseHistory = '/purchase_history',
  matchHistory = '/match_history',
  dragOutHistory = '/drag_out_history',
  subscriptionHistory = '/subscription_history',
  affiliateHistory = '/affiliate_history',
  cart = '/cart',
  receipt = '/receipt',
  singleReceipt = '/receipt/:id',
  affiliatePayout = '/affiliate_payout',
  adminAffiliatePayout = '/admin/affiliate_payout/:userId',
  adminSubscriptionHistory = '/admin_subscription_history',
  adminPurchaseHistory = '/admin_purchase_history',
  adminAffiliateHistory = '/admin_affiliate_history',
  affiliators = '/admin/affiliates',
  addUser = '/admin/add_user',
  viewUser = '/admin/view_user/:userId',
  editUser = '/admin/edit_user/:userId',
  adminPayment = '/admin/payment/:userId',
  favourites = '/favourites',
  reTagging = '/retagging',
}
export enum NonAuthRoutesEnum {
  login = '/',
  register = '/register',
  publicCart = '/publicCart',
  registerSuccess = '/register_success',
  forgotPassword = '/forgot_password',
  resetPassword = '/reset_password',
  confirmEmail = '/confirm_email',
  privacyPolicy = '/privacy_policy',
  termsAndCondition = '/terms_and_condition',
  unauthorised = '/unauthorised',
  setPassword = '/set_password',
  deactivated = '/deactivated',
  sounds = '/sounds',
  loopProvider = '/loop_provider',
  singleLoopProvider = '/loop_provider/:email',
  plugin = '/plugin/:id/:provider',
}

type RouteType =
  | { path: AuthRoutesEnum.dashboard }
  | { path: AuthRoutesEnum.profile }
  | { path: AuthRoutesEnum.viewUser; params: { userId: string } }
  | { path: AuthRoutesEnum.editUser; params: { userId: string } }
  | { path: AuthRoutesEnum.adminPayment; params: { userId: string } }
  | { path: AuthRoutesEnum.adminAffiliatePayout; params: { userId: string } }
  | { path: NonAuthRoutesEnum.singleLoopProvider; params: { email: string } };

type RArgsWithParams = Extract<RouteType, { path: any; params: any }>;

export function createRoute(args: RouteType) {
  // Return if no params found.
  if (Object.prototype.hasOwnProperty.call(args, 'params') === false)
    return args.path;

  // Creating path by replacing params in route definition
  return Object.entries((args as RArgsWithParams).params).reduce(
    (previousValue: string, [param, value]) =>
      previousValue.replace(`:${param}`, '' + value),
    args.path
  );
}

import { createContext } from 'react';

type OgvPlayerContextType = {
  OgvPlaying: boolean;
  OgvPlayingFrom: string;
  OgvPlayingIndex: string;
  OgvPlayerWaiting: boolean;
  OgvHandlePlay: (
    event: any,
    id: string,
    playingFrom?: string,
    rbpm?: number,
    tbpm?: number,
    shift?: number
  ) => void;
  OgvHandlePause: (e?: any) => void;
};

export const OgvPlayerContext =
  createContext<Partial<OgvPlayerContextType | null>>(null);

export default {
  styleOverrides: {
    root: {
      height: '80px',
      boxShadow: '0px 3px 6px #00000016',
      display: 'flex',
      justifyContent: 'center',
    },
  },
};

import {InputBaseClasses, Theme} from '@mui/material';
import {OverridesStyleRules} from '@mui/material/styles/overrides';

export default {
  styleOverrides: {
    root: ({theme}) => ({
      color: theme.palette.defaults.white,
    }),
    input: ({theme}) => ({
      fontFamily: theme['fonts'].main,
      color: theme.palette.defaults.white,
    }),
  } as Partial<
    OverridesStyleRules<keyof InputBaseClasses, 'MuiInputBase', Omit<Theme, 'components'>>
  >,
};

import {
    HistoryActionType,
    SET_SUBSCRIPTION_HISTORY_DATA,
    SUBSCRIPTION_HISTORY_LOADING
} from '../types/actions';
import { SubscriptionDataArray } from '../types/subscriptionHistory';

const initialState: SubscriptionDataArray = {
    data: [],
    loading: true,
    title: 'Subscription History'
};

const subscriptionHistoryReducer = (
    state = initialState,
    action: HistoryActionType
): SubscriptionDataArray => {
    switch (action.type) {
        case SET_SUBSCRIPTION_HISTORY_DATA:
            return {
                ...state,
                data: [...action.data],
                loading: false
            };
        case SUBSCRIPTION_HISTORY_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return state;
    }
};
export { subscriptionHistoryReducer };

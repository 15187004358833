import {Theme, TypographyClasses} from '@mui/material';
import {OverridesStyleRules} from '@mui/material/styles/overrides';

export default {
  styleOverrides: {
    root: ({theme}) => ({
      color: theme.palette.text.secondary,
    }),
  } as Partial<
    OverridesStyleRules<keyof TypographyClasses, 'MuiTypography', Omit<Theme, 'components'>>
  >,
};

import {Dispatch} from 'redux';
import {
  AppActions,
  SET_USER_VAT,
  SET_VAT_LOADING,
  SUBSCRIPTION_HISTORY_LOADING,
} from '../types/actions';
import {API} from '../../config/config';
import {SET_PACKAGES, SET_SUBSCRIPTION_HISTORY_DATA} from '../types/actions';
import {Packages} from '../types/Packages';
import {SubscriptionHistoryData} from '../types/subscriptionHistory';

export const setPackages = (data: Packages[]): AppActions => ({
  payload: data,
  type: SET_PACKAGES,
});

export const getPackages = () => (dispatch: Dispatch<AppActions>) => {
  API.get('/package')
    .then(res => {
      dispatch(setPackages(res.data.body.data));
    })
    .catch(err => {
      //console.log(err);
    });
};

export const setSubscriptionHistory = (data: SubscriptionHistoryData[]): AppActions => ({
  data,
  type: SET_SUBSCRIPTION_HISTORY_DATA,
});

export const getSubscriptionHistory = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({type: SUBSCRIPTION_HISTORY_LOADING, payload: true});
  API.get('/subscription')
    .then(res => {
      if (res?.data?.statusCode === 400) return null;
      dispatch(setSubscriptionHistory(res.data));
    })
    .catch(err => {
      //console.log(err);
    });
};

export const getUserVat = country => (dispatch: Dispatch<AppActions>) => {
  API.get(`/vat/country/${country}`)
    .then(res => {
      // setVat(res.data.body.data.percentage);
      // setStripeTaxId(res.data.body.data.stripeTaxId);
      dispatch({
        type: SET_USER_VAT,
        payload: {
          vat: res.data.body.data.percentage,
          stripeTaxId: res.data.body.data.stripeTaxId,
          vatCountry: res.data.body.data.vatCountry,
        },
      });
    })
    .catch(err => {
      console.error(err.message); // eslint-disable-line no-console
    });
};

import {
    FavouritesActionType,
    REMOVE_ONE_FAVOURITE_DATA,
    SET_FAVOURITES_DATA,
    ADD_FAVOURITES_DATA,
    FAVOURITE_DATA_LOADING_TOGGLE,
    CLEAR_FAVOURITE_DATA
} from './../types/actions';
import _ from 'lodash';
import { FavouriteItemArray } from '../types/Favourites';

const initialState: FavouriteItemArray = {
    loops: [],
    loading: true,
    total: 0
};

const FavouriteReducer = (
    state = initialState,
    action: FavouritesActionType
): FavouriteItemArray => {
    switch (action.type) {
        case SET_FAVOURITES_DATA:
            return {
                ...state,
                ...action.data,
                loading: false
            };
        case ADD_FAVOURITES_DATA:
            return {
                ...state,
                loops: [...state.loops, action.data],
                total: state.total + 1,
                loading: false
            };
        case REMOVE_ONE_FAVOURITE_DATA: {
            const favFiltered = state.loops.filter((item) => {
                return item.LoopId !== action.LoopId;
            });
            return {
                loops: [...favFiltered],
                loading: true,
                total: state.total - 1
            };
        }
        case FAVOURITE_DATA_LOADING_TOGGLE: {
            return {
                ...state,
                loading: action.loading
            };
        }
        case CLEAR_FAVOURITE_DATA: {
            return {
                ...initialState
            };
        }
        default:
            return state;
    }
};
export { FavouriteReducer };

import axios from 'axios';
import { SetStateAction } from 'react';
export { matchWithSndlkId } from './matchWithSndlk';
interface TLoopMatching {
  url: string;
  file: File;
  setUploadPercent?: (value: SetStateAction<number>) => void;
  instruments?: string[];
  genre?: string[];
}
export const loopMatchWithFIle = async <T>({
  url,
  setUploadPercent,
  file,
  instruments,
  genre,
}: TLoopMatching): Promise<T | null> => {
  const formData = new FormData();
  formData.append('loop', file);
  formData.append('feature-name', 'harmony');
  if (instruments) formData.append('Instrument', instruments.join(','));
  if (genre) formData.append('Genre', genre.join(','));
  try {
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (setUploadPercent) setUploadPercent(percentCompleted);
      },
    });
    return response?.data?.Matches;
  } catch (error) {
    // console.log(error);
  }
  return null;
};

import {ButtonClasses, Theme} from '@mui/material';
import {OverridesStyleRules} from '@mui/material/styles/overrides';

export default {
  styleOverrides: {
    root: {
      boxShadow: '0px 1px 3px rgba(0, 0, 0, .2)',
      height: '49px',
      minWidth: '144px',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '21px',
      letterSpacing: '-0.05px',
      borderRadius: '37px',
      margin: '0px 5px',
      padding: '14px 32px',
    },
    sizeSmall: {
      minWidth: '95px',
      height: '37px',
      padding: '12px 24px',
      textTransform: 'capitalize',
      '& .MuiSvgIcon-root': {
        width: '17px',
        height: '17px',
      },
    },
    text: ({theme}) => ({
      color: theme.palette.defaults.white,
      boxShadow: 'none',
    }),
    textError: ({theme}) => ({
      color: theme.palette.error.main,
    }),
    containedPrimary: ({theme}) => ({
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
      color: 'white',
      letterSpacing: 'normal',
      '&:hover': {
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: '#8236FF20',
        transition: '.4s',
      },
      '&:disabled': {
        backgroundColor: theme.palette.grey[700],
        color: '#B3B3B3',
        border: '2px solid #333',
      },
    }),
    containedSecondary: {
      color: 'white',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, .2)',
      fontSize: '13px',
    },
    outlinedSecondary: {
      boxShadow: '0px 1px 3px rgba(0, 0, 0, .2)',
      fontSize: '13px',
    },
    outlinedPrimary: ({theme}) => ({
      color: theme.palette.defaults.white,
      backgroundColor: 'transparent',
      border: `2px solid ${theme.palette.primary.main}`,
      '&:hover': {
        color: theme.palette.defaults.white,
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.primary.main,
        transition: '.3s',
      },
      '&:disabled': {
        color: theme.palette.defaults.white,
        border: `2px solid ${theme.palette.grey[700]}`,
        backgroundColor: theme.palette.grey[700],
      },
    }),
  } as Partial<OverridesStyleRules<keyof ButtonClasses, 'MuiButton', Omit<Theme, 'components'>>>,
};

import {FormLabelClasses, Theme} from '@mui/material';
import {OverridesStyleRules} from '@mui/material/styles/overrides';

export default {
  styleOverrides: {
    root: ({theme}) => ({
      fontFamily: theme.fonts.main,
      color: '#9E9E9E',
    }),
  } as Partial<
    OverridesStyleRules<keyof FormLabelClasses, 'MuiFormLabel', Omit<Theme, 'components'>>
  >,
};

import {TablePaginationClasses, Theme, alpha} from '@mui/material';
import {OverridesStyleRules} from '@mui/material/styles/overrides';

export default {
  styleOverrides: {
    root: {
      '&:last-child': {
        padding: '1rem 3rem 0 0',
      },
    },
    displayedRows:({theme}) => ({
      color: theme.palette.defaults.white,
    }),
    menuItem:({theme}) => ({
      color: theme.palette.defaults.white,
      '&.Mui-selected': {
        background: alpha(theme.palette.defaults.white, 0.1)
      },
      '&:hover': {
        background: alpha(theme.palette.defaults.white, 0.1)
      }
    }),

  } as Partial<OverridesStyleRules<keyof TablePaginationClasses, 'MuiTablePagination', Omit<Theme, 'components'>>>,
};

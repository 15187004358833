import { ProfileData } from '../types/profile';
import {
    ProfileActionType,
    SET_PROFILE_DATA,
    CLEAR_PROFILE_DATA,
    SET_PROFILE_EDIT
} from '../types/actions';
import { CLEAR_PROFILE_LOADING } from './../types/actions';

const initialState: ProfileData = {
    cognitoUserId: '',
    company: '',
    country: '',
    currency: '',
    createdAt: '',
    email: '',
    image: '',
    links: [],
    role: '',
    status: '',
    thumbnailImage: '',
    type: '',
    updatedAt: '',
    userConfirmed: false,
    userFirstName: '',
    userLastName: '',
    userNameRef: '',
    _id: '',
    availablePoints: 0,
    subscription: {},
    affiliate: false,
    affiliateLink: '',
    affiliateBalance: 0,
    affiliateClicks: 0,
    affiliateEarning: 0,
    affiliateBuys: 0,
    edit: false,
    loading: true
};
const profileReducer = (state = initialState, action: ProfileActionType): ProfileData => {
    switch (action.type) {
        case SET_PROFILE_DATA:
            return { ...state, ...action.data, loading: false };
        case CLEAR_PROFILE_DATA:
            return {
                ...initialState
            };
        case CLEAR_PROFILE_LOADING:
            return {
                ...state,
                loading: false
            };
        case SET_PROFILE_EDIT:
            return {
                ...state,
                edit: action.payload
            };
        default:
            return state;
    }
};

export { profileReducer };

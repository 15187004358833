import {Theme} from '@mui/material';
import {OverridesStyleRules} from '@mui/material/styles/overrides';

export default {
  styleOverrides: {
    root: ({theme}) => ({
      color: theme.palette.defaults.white,
      // '& .MuiInputBase-root.Mui-disabled': {
      //   '& > fieldset': {
      //     borderColor: theme.palette.grey[200],
      //   },
      // },
    }),

    InputLabelProps: {
      shrink: true,
    },
  } as Partial<OverridesStyleRules<'root', 'MuiTextField', Omit<Theme, 'components'>>>,
};

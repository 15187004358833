import { DragOutHistoryDataArray } from '../types/dragOutHistory';
import { DRAG_OUT_SOUNDS_TOGGLE_LOADING, HistoryActionType } from '../types/actions';
import { SET_DRAG_OUT_HISTORY_DATA } from '../types/actions';

const dragOutHistoryState: DragOutHistoryDataArray = {
    loops: [],
    loading: true,
    index: 0,
    total: 0
};

const dragOutHistoryReducer = (
    state = dragOutHistoryState,
    action: HistoryActionType
): DragOutHistoryDataArray => {
    switch (action.type) {
        case SET_DRAG_OUT_HISTORY_DATA:
            return { ...state, ...action.data, loading: false };
        case DRAG_OUT_SOUNDS_TOGGLE_LOADING:
            return { ...state, loops: [], loading: action.loading };
        default:
            return state;
    }
};

export { dragOutHistoryReducer };

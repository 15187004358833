import { AffiliateHistoryType } from '../types/actions';
import { AffiliatePayoutHistoryState } from '../types/affiliatePayoutHistory';
import { SET_AFFILIATE_PAYOUT_DATA } from './../types/actions';

const affiliatePayoutHistoryState: AffiliatePayoutHistoryState = {
    data: [],
    loading: true
};

const affiliatePayoutHistoryReducer = (
    state = affiliatePayoutHistoryState,
    action: AffiliateHistoryType
): AffiliatePayoutHistoryState => {
    switch (action.type) {
        case SET_AFFILIATE_PAYOUT_DATA:
            return { ...state, data: [...action.data], loading: false };
        default:
            return state;
    }
};

export { affiliatePayoutHistoryReducer };

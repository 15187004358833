import { AdminUserActionType, SET_ADMIN_AFFILIATE_PAYOUT_HISTORY_DATA } from '../types/actions';
import { AdminAffiliatePayoutHistoryState } from './../types/adminAffiliatePayoutHistory';

const affiliatePayoutHistoryState: AdminAffiliatePayoutHistoryState = {
    data: [],
    loading: true
};

const adminAffiliatePayoutHistoryReducer = (
    state = affiliatePayoutHistoryState,
    action: AdminUserActionType
): AdminAffiliatePayoutHistoryState => {
    switch (action.type) {
        case SET_ADMIN_AFFILIATE_PAYOUT_HISTORY_DATA:
            return { ...state, data: [...action.data], loading: false };
        default:
            return state;
    }
};

export { adminAffiliatePayoutHistoryReducer };

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { ClearAllLocalData } from './../utils/ClearAllLocalData';

interface Props {
  Component: React.FC;
  redirectPath: string;
}

const RedirectRoute = ({ Component, redirectPath }: Props): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setIsAuthenticated(!!user);
        setIsLoading(false);
      })
      .catch((_) => {
        ClearAllLocalData(dispatch);
      });
  });
  if (isLoading) return null;

  return !isLoading && isAuthenticated ? (
    <Component />
  ) : (
    <Navigate
      to={{
        pathname: redirectPath,
      }}
    />
  );
};
export default RedirectRoute;

import {
    PROGRESS_TOGGLE,
    SoundsActionType,
    SET_ALL_SOUNDS,
    ALL_SOUNDS_TOGGLE_LOADING
} from '../types/actions';
import { SoundState } from '../types/sounds';

const soundState: SoundState = {
    loops: [],
    loading: true,
    total: 0,
    index: 0
};

const soundsReducer = (state = soundState, action: SoundsActionType): SoundState => {
    switch (action.type) {
        case SET_ALL_SOUNDS:
            return { ...state, ...action.data, loading: false };
        case ALL_SOUNDS_TOGGLE_LOADING:
            return { ...state, loading: action.loading };
        default:
            return state;
    }
};
export { soundsReducer };

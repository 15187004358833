import { useEffect, useState } from 'react';

export const LocalPlayerHook = () => {
    // const player = document.getElementById('audio') as HTMLMediaElement;
    const [player, setPlayer] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [playingFrom, setPlayingFrom] = useState('');

    //Player stop/pause state handle.
    useEffect(() => {
        const audio = new Audio('');
        setPlayer(audio);
        audio.onpause = () => {
            setPlaying(false);
        };
    }, []);

    // Loop Player --> Play
    const handlePlay = (file: any, event = null, playingFrom = '') => {
        event?.stopPropagation?.();
        setPlayingFrom(playingFrom);
        player.src = file.preview;
        player.load();
        setPlaying(true);
        player.play();
    };
    // Loop Player --> Pause
    const handlePause = (event = null) => {
        event?.stopPropagation?.();
        player.pause();
        player.currentTime = 0;
        setPlaying(false);
    };

    return [playing, playingFrom, handlePlay, handlePause] as const;
};

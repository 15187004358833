export default {
  styleOverrides: {
    root: {
      color: '#666666',
      '&.Mui-focused': {
        color: '#666666'
      },
    },

    disabled: {},
    error: {},
  },
};

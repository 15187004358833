import {User} from '../types/User';
import axios from 'axios';
import {AppActions, PROGRESS_TOGGLE, TOGGLE_CART_LOADING} from '../types/actions';
import {Auth} from 'aws-amplify';
import {Dispatch} from 'redux';
import {AppState} from '../index';
import {baseUrl, loopAPI} from '../../config/config';
import {API} from '../../config/config';
import {UPDATE_CART_DATA} from '../types/actions';
import {FAILED_STATUS, SUCCESS_STATUS} from '../types/Notification';
import {NonAuthRoutesEnum} from '../../routes/RouteConst';
import {CartItem} from '../types/cart';
import unionBy from 'lodash/unionBy';
import differenceBy from 'lodash/differenceBy';
import {UserRoles} from '../../utils/UserRoles';
import {ClearAllLocalData} from './../../utils/ClearAllLocalData';
import {RegisterToPlugin} from './../../utils/RegisterToPlugin';
import {NavigateFunction} from 'react-router-dom';

export const setUser = (user: User): AppActions => ({
  user,
  type: 'SET_USER',
});

export const setNotification = (message: string, status: string): AppActions => ({
  payload: {
    message,
    status,
  },
  type: 'SHOW_NOTIFICATION',
});

export const SetErrorMessage = (message: string): AppActions => ({
  payload: {
    message,
  },
  type: 'SHOW_ERROR_MESSAGE',
});

// User Info -->
// This function is deprecated;
export const getCurrentUser =
  (userrole = '', userstatus = '') =>
  (dispatch: Dispatch<AppActions>): void => {
    Auth.currentUserInfo()
      .then(res => {
        const firstName = res.attributes['custom:userFirstName'];
        const lastName = res.attributes['custom:userLastName'];
        const email = res.attributes['email'];
        let role;
        if (res.attributes['custom:role']) {
          role = res.attributes['custom:role'];
        } else {
          role = userrole;
        }
        let status = '';
        if (userstatus) {
          status = userstatus;
        }

        dispatch(
          setUser({
            firstName: firstName,
            lastName: lastName,
            email: email,
            role: role,
            isAdmin: role === UserRoles.admin,
            isUser: role === UserRoles.user,
            isLoopProvider: role === UserRoles.loopProvider,
            status: status,
          })
        );
      })
      .catch(err => console.error(err)); // eslint-disable-line no-console
  };

// USER LOGIN -->
export const cartMergeWithLocal = (dispatch: Dispatch<AppActions>) => {
  const previousCart = JSON.parse(localStorage.getItem('State'))?.cart?.data;

  API.get('/cart')
    .then(res => {
      const cart = res.data;
      const unionCart: any = unionBy(cart, previousCart, '_id');
      const uniqCart = differenceBy(previousCart, cart, '_id');
      const data = unionCart as CartItem[];
      dispatch({
        data,
        type: UPDATE_CART_DATA,
      });
      if (uniqCart.length > 0) {
        API.post('/cart', uniqCart)
          .then()
          .catch(err => {
            console.error(err); // eslint-disable-line no-console
          });
      }
    })
    .catch(err => {
      console.error(err); // eslint-disable-line no-console
      dispatch({type: TOGGLE_CART_LOADING, payload: false});
    });
};

interface LoginValues {
  email: string;
  password: string;
  history: any;
  enqueueSnackbar: (props: any, variant?: any) => any;
  resetLoginForm: () => any;
}
export const loginUser = (values: LoginValues) => (dispatch: Dispatch<AppActions>) => {
  const {email, password, enqueueSnackbar, resetLoginForm} = values;
  Auth.signIn({
    username: email,
    password,
  })
    .then(res => {
      // if (res?.attributes?.['custom:status'] === 'deactivated') {
      //     enqueueSnackbar('Sorry your account has been disabled. Contact with admin', {
      //         variant: 'error',
      //         persist: true
      //     });
      //     resetLoginForm();
      // }
      if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
        Auth.completeNewPassword(
          res, // the Cognito User Object
          password // the new password
        );
      }
      RegisterToPlugin({username: email, password}, loopAPI);
      resetLoginForm();
    })
    .catch(err => {
      dispatch({type: PROGRESS_TOGGLE, payload: false});
      // User is not confirmed.
      if (err.message === 'User is not confirmed.') {
        dispatch(
          setNotification(
            'User is not confirmed. Please check your email for a verification link.',
            FAILED_STATUS
          )
        );
        enqueueSnackbar('User is not confirmed. Please check your email for a verification link.', {
          variant: 'error',
        });
      } else {
        dispatch(SetErrorMessage(err.message));
      }
      resetLoginForm();
    });
};

// USER Signup
interface SignUpValues {
  firstName: string;
  lastName: string;
  country: string;
  currency: string;
  email: string;
  password: string;
  navigate: any;
  enqueueSnackbar: (props: any) => any;
  resetRegisterForm: () => any;
}
export const signupUser = (values: SignUpValues) => {
  const {
    firstName,
    lastName,
    country,
    currency,
    email,
    password,
    enqueueSnackbar,
    navigate,
    resetRegisterForm,
  } = values;
  axios
    .post(`${baseUrl}/auth/register`, {
      firstName,
      lastName,
      country,
      currency,
      email,
      password,
    })
    .then(_ => {
      enqueueSnackbar('Registration Successful!');
      resetRegisterForm();
      navigate(NonAuthRoutesEnum.registerSuccess);
    })
    .catch(err => {
      if (err.response.data.statusCode === 400) {
        enqueueSnackbar(err.response.data.message);
        resetRegisterForm();
      }
    });
};

// User Forgot Password
interface ForgotPassValues {
  email: string;
  enqueueSnackbar: (props: any) => any;
  resetForgotPassForm: () => any;
  navigate: any;
}
export const forgotPassword = (values: ForgotPassValues) => {
  const {email, enqueueSnackbar, resetForgotPassForm, navigate} = values;
  axios
    .post(`${baseUrl}/auth/forgotPassword`, {
      email,
    })
    .then(res => {
      enqueueSnackbar('A reset password verification code has been sent to your email.');
      navigate(NonAuthRoutesEnum.resetPassword);
    })
    .catch(err => {
      enqueueSnackbar(`Email Not Found!`);
      resetForgotPassForm();
    });
};

// User Reset Password
interface ResetValues {
  email: string;
  code: string;
  password: string;
  enqueueSnackbar: (props: any) => any;
  resetResetPassForm: () => any;
  navigate: NavigateFunction;
}

export const resetPassword = (values: ResetValues) => {
  const {email, code, password, enqueueSnackbar, resetResetPassForm, navigate} = values;
  axios
    .post(`${baseUrl}/auth/resetPassword`, {
      email,
      code,
      newPassword: password,
    })
    .then(res => {
      if (res.data.statusCode === 400) {
        enqueueSnackbar('Invalid Code!');
        resetResetPassForm();
      } else {
        enqueueSnackbar(res.data.body.message);
        navigate(NonAuthRoutesEnum.login);
        resetResetPassForm();
      }
    })
    .catch(err => {
      console.error(err); // eslint-disable-line no-console
      resetResetPassForm();
      // if (err.response.data.statusCode === 400) {
      //     enqueueSnackbar(err.response.data.message);
      // }
    });
};

// User Signout
export const signOut =
  (navigate: NavigateFunction) =>
  (
    dispatch: Dispatch<AppActions>
    // getState: () => AppState
  ): void => {
    Auth.signOut()
      .then(_ => {
        ClearAllLocalData(dispatch);
        navigate(NonAuthRoutesEnum.login);
      })
      .catch(err => console.error(err)); // eslint-disable-line no-console
  };

// Email Confirm
interface ConfirmEmailValues {
  email: string | string[] | null | undefined;
  code: string | string[] | null | undefined;
}
export const confrimEmail =
  (values: ConfirmEmailValues) =>
  (dispatch: Dispatch<AppActions>, getState: () => AppState): void => {
    axios
      .post(`${baseUrl}/auth/verify`, {
        email: values.email,
        code: values.code,
      })
      .then(res => {
        if (res.data.statusCode === 200) {
          dispatch(setNotification('Registration Successful!', SUCCESS_STATUS));
        } else {
          dispatch(setNotification('Confirmation Failed!', FAILED_STATUS));
        }
      })
      .catch(err => {
        console.error(err); // eslint-disable-line no-console
        dispatch(setNotification(err.response?.data.message, 'failed'));
        // dispatch(setNotification('', ''));
      });
  };

// Resend - Confirm Email
interface ResendConfirmEmailValues {
  email: string | string[] | null | undefined;
  enqueueSnackbar: (props: any) => any;
}
export const resendConfrimEmail =
  (values: ResendConfirmEmailValues) => (dispatch: Dispatch<AppActions>) => {
    axios
      .post(`${baseUrl}/auth/resendConfirmationCode`, {
        email: values.email,
      })
      .then(res => {
        if (res.data.statusCode === 200) {
          values.enqueueSnackbar(res.data.body.message);
          dispatch(setNotification('', ''));
        }
      })
      .catch(err => {
        console.error(err); // eslint-disable-line no-console
        values.enqueueSnackbar(err.response?.data.message);
      });
  };

import palette from "../palette";

export default {
  styleOverrides: {
    root: {
      background: '#333',
      color: palette.defaults.white,
    },
    deleteIcon: {
      color: '#666',
      '&:hover':{
        color: '#888'
      }
    },
  },
};

import React, {Suspense, lazy} from 'react';
import {NonAuthRoutesEnum, AuthRoutesEnum} from './RouteConst';
import {Routes as Switch, Route, useLocation} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import {UserRoles} from '../utils/UserRoles';
import {lazyRetry} from './LazyRetry';

// Material UI -->
import {CircularProgress} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import {useSelector} from 'react-redux';
import RedirectRoute from './RedirectRoute';

// Components -->
const Login = lazy(() => lazyRetry(() => import('../components/Auth/Login/Login')));
const Register = lazy(() => lazyRetry(() => import('../components/Auth/Register/Register')));
const ForgotPass = lazy(() => lazyRetry(() => import('../components/Auth/ForgotPass/ForgotPass')));
const NotFound = lazy(() => lazyRetry(() => import('../components/NotFound/NotFound')));
const Dashboard = lazy(() => lazyRetry(() => import('../components/Dashboard/Dashboard')));
const AddUser = lazy(() => lazyRetry(() => import('../components/Dashboard/Users/AddUser')));
const ViewUser = lazy(() => lazyRetry(() => import('../components/Dashboard/Users/ViewUser')));
const EditUser = lazy(() => lazyRetry(() => import('./../components/Dashboard/Users/EditUser')));
const DashboardPayment = lazy(() =>
  lazyRetry(() => import('../components/Dashboard/Payment/RecordPayment'))
);
const ResetPassword = lazy(() =>
  lazyRetry(() => import('../components/Auth/ResetPassword/ResetPassword'))
);
const RegisterSuccess = lazy(() =>
  lazyRetry(() => import('../components/Auth/RegisterSuccess/RegisterSuccess'))
);
const Profile = lazy(() => lazyRetry(() => import('../components/Applications/Profile/Profile')));
const ProfileEdit = lazy(() =>
  lazyRetry(() => import('../components/LoopProviderProfile/ProfileEdit'))
);
const Subscription = lazy(() =>
  lazyRetry(() => import('../components/Applications/Subscription/Subscription'))
);
const Subscribed = lazy(() =>
  lazyRetry(() => import('./../components/Applications/Subscription/Subscribed'))
);
const SetPassword = lazy(() =>
  lazyRetry(() => import('../components/Auth/SetPassword/SetPassword'))
);
const SubscriptionConfirm = lazy(() =>
  lazyRetry(() => import('../components/Applications/Subscription/SubscriptionConfirm'))
);
const SalesHistory = lazy(() =>
  lazyRetry(() => import('../components/History/SalesHistory/SalesHistory'))
);
const PurchaseHistory = lazy(() =>
  lazyRetry(() => import('../components/History/PurchaseHistory/PurchaseHistory'))
);
const MatchHistory = lazy(() =>
  lazyRetry(() => import('../components/History/MatchHistory/MatchHistory'))
);
const DragOutHistory = lazy(() =>
  lazyRetry(() => import('../components/History/DragOutHistory/DragOutHistory'))
);
const DashboardAffiliateHistory = lazy(() =>
  lazyRetry(() => import('../components/DashboardAffiliateHistory/DAffiliateHistory'))
);
const DashboardAffiliatePayoutHistory = lazy(() =>
  lazyRetry(() => import('../components/DashboardAffiliatePayoutHistory/DAffiliatePayoutHistory'))
);
const SubscriptionHistory = lazy(() =>
  lazyRetry(() => import('../components/History/SubscriptionHistory/SubscriptionHistory'))
);
const AffiliateHistory = lazy(() =>
  lazyRetry(() => import('./../components/History/AffiliateHistory/AffiliateHistory'))
);
const AffiliatePayoutHistory = lazy(() =>
  lazyRetry(() => import('./../components/History/AffiliatePayoutHistory/AffiliatePayoutHistory'))
);
const Favourites = lazy(() =>
  lazyRetry(() => import('../components/Applications/Favourites/Favourites'))
);
const Cart = lazy(() => lazyRetry(() => import('../components/Applications/Cart/Cart')));
const TermsAndCondition = lazy(() =>
  lazyRetry(() => import('./../components/TermsAndConditon/TermsAndCondition'))
);

const PublicCart = lazy(() =>
  lazyRetry(() => import('../components/Applications/Cart/PublicCart'))
);
const SingleReceipt = lazy(() =>
  lazyRetry(() => import('../components/Applications/Receipt/SingleReceipt'))
);
const CofirmEmail = lazy(() =>
  lazyRetry(() => import('../components/Auth/ConfirmEmail/ConfirmEmail'))
);
const UnAuthorised = lazy(() =>
  lazyRetry(() => import('../components/Auth/UnAuthorised/UnAuthorised'))
);
const Deactivated = lazy(() =>
  lazyRetry(() => import('./../components/Auth/UnAuthorised/Deactivated'))
);
const PrivacyPolicy = lazy(() =>
  lazyRetry(() => import('./../components/PrivacyPolicy/PrivacyPolicy'))
);
const AdminPurchaseHistory = lazy(() =>
  lazyRetry(() => import('./../components/DashboardPurchaseHistory/DPurchaseHistory'))
);
const AdminSubscriptionHistory = lazy(() =>
  lazyRetry(() => import('./../components/DashboardSubscriptionHistory/DSubscriptionHistory'))
);
const DashboardAffiliators = lazy(() =>
  lazyRetry(() => import('./../components/DashboardAffiliators/DAffiliators'))
);
const Sound = lazy(() => lazyRetry(() => import('../components/Sounds/sound')));
const LoopProvider = lazy(() =>
  lazyRetry(() => import('../components/LoopProviderProfile/LoopProviderProfile'))
);
const PluginLogin = lazy(() => lazyRetry(() => import('./../components/Auth/Plugin/login')));
const ReTagging = lazy(() => lazyRetry(() => import('./../components/Retagging/ReTagging')));

// Routes -->
const Routes: React.FC = () => {
  const notifications = useSelector((state: any) => state.notification);
  const location = useLocation();

  return (
    <Suspense
      fallback={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </div>
      }
    >
      {notifications.progress ? (
        <LinearProgress
          style={{zIndex: 9999, position: 'fixed', top: '0', width: '100%'}}
          color="secondary"
        />
      ) : null}
      <Switch>
        <Route path="*" element={<NotFound />} />
        <Route path={NonAuthRoutesEnum.login} element={<Login />} />
        <Route path={NonAuthRoutesEnum.plugin} element={<PluginLogin />} />
        <Route path={NonAuthRoutesEnum.setPassword} element={<SetPassword />} />
        <Route
          path={AuthRoutesEnum.dashboard}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.admin)]}
              Component={Dashboard}
              location={location}
            />
          }
        />
        <Route
          path={AuthRoutesEnum.reTagging}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.admin)]}
              Component={ReTagging}
              location={location}
            />
          }
        />

        {/* <PrivateRoute
          requiredRoles={[String(UserRoles.admin)]}
          path={AuthRoutesEnum.dashboard}
          // Component={SetPassword}
          Component={Dashboard}
        /> */}
        <Route
          path={AuthRoutesEnum.addUser}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.admin)]}
              Component={AddUser}
              location={location}
            />
          }
        />
        <Route
          path={AuthRoutesEnum.viewUser}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.admin)]}
              Component={ViewUser}
              location={location}
            />
          }
        />
        <Route
          path={AuthRoutesEnum.editUser}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.admin)]}
              Component={EditUser}
              location={location}
            />
          }
        />
        <Route
          path={AuthRoutesEnum.adminPayment}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.admin)]}
              Component={DashboardPayment}
              location={location}
            />
          }
        />
        <Route
          path={AuthRoutesEnum.adminPurchaseHistory}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.admin)]}
              Component={AdminPurchaseHistory}
              location={location}
            />
          }
        />

        <Route
          path={AuthRoutesEnum.adminSubscriptionHistory}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.admin)]}
              Component={AdminSubscriptionHistory}
              location={location}
            />
          }
        />

        <Route
          path={AuthRoutesEnum.affiliators}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.admin)]}
              Component={DashboardAffiliators}
              location={location}
            />
          }
        />
        <Route
          path={AuthRoutesEnum.adminAffiliateHistory}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.admin)]}
              Component={DashboardAffiliateHistory}
              location={location}
            />
          }
        />
        <Route
          path={AuthRoutesEnum.adminAffiliatePayout}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.admin)]}
              Component={DashboardAffiliatePayoutHistory}
              location={location}
            />
          }
        />
        <Route
          path={AuthRoutesEnum.profile}
          element={
            <PrivateRoute
              requiredRoles={[
                String(UserRoles.user),
                String(UserRoles.admin),
                String(UserRoles.loopProvider),
              ]}
              Component={Profile}
              location={location}
            />
          }
        />
        <Route
          path={AuthRoutesEnum.loopMasterEdit}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.loopProvider)]}
              Component={ProfileEdit}
              location={location}
            />
          }
        />
        <Route
          path={AuthRoutesEnum.subscription}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.user), String(UserRoles.loopProvider)]}
              Component={Subscription}
              location={location}
            />
          }
        />
        <Route
          path={AuthRoutesEnum.subscribed}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.user), String(UserRoles.loopProvider)]}
              Component={Subscribed}
              location={location}
            />
          }
        />
        <Route
          path={AuthRoutesEnum.subscriptionConfirm}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.user), String(UserRoles.loopProvider)]}
              Component={SubscriptionConfirm}
              location={location}
            />
          }
        />
        <Route
          path={AuthRoutesEnum.salesHistory}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.loopProvider)]}
              Component={SalesHistory}
              location={location}
            />
          }
        />
        <Route
          path={AuthRoutesEnum.purchaseHistory}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.user), String(UserRoles.loopProvider)]}
              Component={PurchaseHistory}
              location={location}
            />
          }
        />
        <Route
          path={AuthRoutesEnum.matchHistory}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.user), String(UserRoles.loopProvider)]}
              Component={MatchHistory}
              location={location}
            />
          }
        />
        <Route
          path={AuthRoutesEnum.dragOutHistory}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.user), String(UserRoles.loopProvider)]}
              Component={DragOutHistory}
              location={location}
            />
          }
        />
        <Route
          path={AuthRoutesEnum.subscriptionHistory}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.user), String(UserRoles.loopProvider)]}
              Component={SubscriptionHistory}
              location={location}
            />
          }
        />
        <Route
          path={AuthRoutesEnum.affiliateHistory}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.user)]}
              Component={AffiliateHistory}
              location={location}
            />
          }
        />
        <Route
          path={AuthRoutesEnum.affiliatePayout}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.user)]}
              Component={AffiliatePayoutHistory}
              location={location}
            />
          }
        />
        <Route
          path={AuthRoutesEnum.favourites}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.user), String(UserRoles.loopProvider)]}
              Component={Favourites}
              location={location}
            />
          }
        />
        <Route
          path={AuthRoutesEnum.cart}
          element={<RedirectRoute redirectPath={NonAuthRoutesEnum.publicCart} Component={Cart} />}
        />

        {/* <Route
          path={AuthRoutesEnum.receipt}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.user)]}
              Component={Receipt}
              location={location}
            />
          }
        /> */}
        <Route
          path={AuthRoutesEnum.singleReceipt}
          element={
            <PrivateRoute
              requiredRoles={[String(UserRoles.user)]}
              Component={SingleReceipt}
              location={location}
            />
          }
        />
        <Route path={NonAuthRoutesEnum.publicCart} element={<PublicCart />} />
        <Route path={NonAuthRoutesEnum.sounds} element={<Sound />} />
        <Route path={NonAuthRoutesEnum.register} element={<Register />} />
        <Route path={NonAuthRoutesEnum.registerSuccess} element={<RegisterSuccess />} />
        <Route path={NonAuthRoutesEnum.forgotPassword} element={<ForgotPass />} />
        <Route path={NonAuthRoutesEnum.resetPassword} element={<ResetPassword />} />
        <Route path={NonAuthRoutesEnum.confirmEmail} element={<CofirmEmail />} />
        <Route path={NonAuthRoutesEnum.privacyPolicy} element={<PrivacyPolicy />} />
        <Route path={NonAuthRoutesEnum.termsAndCondition} element={<TermsAndCondition />} />
        <Route path={NonAuthRoutesEnum.singleLoopProvider} element={<LoopProvider />} />
        <Route path={NonAuthRoutesEnum.loopProvider} element={<LoopProvider />} />
        <Route path={NonAuthRoutesEnum.unauthorised} element={<UnAuthorised />} />
        <Route path={NonAuthRoutesEnum.deactivated} element={<Deactivated />} />
      </Switch>
    </Suspense>
  );
};
export default React.memo(Routes);

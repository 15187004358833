import { Dispatch, SetStateAction } from 'react';
import { TAllLoops } from '@nxjamahook/types';
import axios from 'axios';

interface TGetAllLoops {
  loopApi: string;
  perPage?: number;
  pageNo?: number;
  setLoopState: Dispatch<SetStateAction<TAllLoops>>;
}
export const GetAllLoops = ({
  loopApi,
  perPage = 6,
  pageNo = 1,
  setLoopState,
}: TGetAllLoops) => {
  axios
    .get(`${loopApi}/loops?perPage=${perPage}&pageNo=${pageNo}`)
    .then((response) => {
      setLoopState(response.data);
    });
};

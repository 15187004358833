import React, { useEffect } from 'react';
import Routes from './routes/Routes';
import { connect, useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  configureAmplify,
  API,
  getSessionToken,
  baseUrl,
} from './config/config';
import { createBaseTheme } from './Theme';
import { AppState } from './store';
// import { User } from './store/types/User';
import { Hub } from 'aws-amplify';
import { setjwtToken } from './config/config';
import { useDispatch } from 'react-redux';
import { cartMergeWithLocal } from './store/actions/userActions';
import {
  CLEAR_PROFILE_LOADING,
  CLEAR_USER,
  PROGRESS_TOGGLE,
  SET_PROFILE_DATA,
  SET_USER,
} from './store/types/actions';
import { getSubscriptionHistory } from './store/actions/subscriptionAction';
import { UserRoles } from './utils/UserRoles';
import axios from 'axios';
import { ClearAllLocalData } from './utils/ClearAllLocalData';
import { UrlExclude } from './utils/AffiliateUtils';
import { useSnackbar } from 'notistack';
import { FAILED_STATUS } from './store/types/Notification';
import { setNotification } from './store/actions/historyActions';
import { GetFavouriteFromBackend } from './store/actions/FavouritesActions';
import {
  LocalPlayerContext,
  LocalPlayerHook,
  TonePlayerHook,
  OgvPlayerContext,
} from '@nxjamahook/common-functions';

const defaultTheme = createTheme();
const theme = createBaseTheme(defaultTheme);

const refreshCacheAndReload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name);
      }
    });
  }
  // delete browser cache and hard reload
  // window.location.reload(true);
};

const App = () => {

  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();
  const userRole = useSelector((state: AppState) => state.user.role);
  const urlParams = new URLSearchParams(window.location.search);
  //Local Player global context
  const [LocalPlaying, LocalPlayingFrom, LocalHandlePlay, LocalHandlePause] =
    LocalPlayerHook();
  // Ogv player handler state
  const [
    OgvPlaying,
    OgvPlayingFrom,
    OgvPlayingIndex,
    OgvPlayerWaiting,
    OgvHandlePlay,
    OgvHandlePause,
  ] = TonePlayerHook();
  const AFID = urlParams.get('afid');
  const paths = window.location.pathname.split('/');
  const path = `/${paths.join('')}`;
  const SetUserProfileData = () => {
    API.get('/user/profile')
      .then((res) => {
        if (res.data.statusCode === 200) {
          dispatch({ type: SET_PROFILE_DATA, data: res.data.body.data });
          const profile = res.data.body.data;
          dispatch({
            user: {
              firstName: profile.userFirstName,
              lastName: profile.userLastName,
              email: profile.email,
              role: profile.role,
              isAdmin: profile.role === UserRoles.admin,
              isUser: profile.role === UserRoles.user,
              isLoopProvider: profile.role === UserRoles.loopProvider,
              status: profile.status,
            },
            type: SET_USER,
          });
        } else if (res.data.body.message === 'Invalid Token') {
          ClearAllLocalData(dispatch);
        } else {
          enqueueSnackbar(res.data.body.message, {
            variant: 'error',
            persist: true,
          });
          dispatch(setNotification('', FAILED_STATUS));
        }
        dispatch({ type: PROGRESS_TOGGLE, payload: false });
      })
      .catch(() => {
        dispatch({ type: CLEAR_PROFILE_LOADING });
        dispatch({ type: PROGRESS_TOGGLE, payload: false });
        // console.log(err);
      });
  };

  const DoesUserExist = () => {
    API.get('/user/profile')
      .then((res) => {
        if (res.data.statusCode === 400) {
          dispatch({ type: CLEAR_USER });
          ClearAllLocalData(dispatch);
        }
        dispatch({ type: PROGRESS_TOGGLE, payload: false });
      })
      .catch((err) => {
        if (err?.code === 'UserNotFoundException') {
          ClearAllLocalData(dispatch);
        }
      });
  };
  useEffect(() => {
    if (AFID) {
      localStorage.setItem('afid', AFID);
      axios.get(`${baseUrl}/public/affiliateClicks/${AFID}`);
      if (userRole === '' && !UrlExclude.includes(path)) {
        window.location.href = 'https://jamahook.com/';
      }
    }
    refreshCacheAndReload();
    dispatch(getSubscriptionHistory());
  }, []);

  useEffect(() => {
    configureAmplify();
    setjwtToken();
    DoesUserExist();
    Hub.listen('auth', ({ payload: { event, data } }) => {
      // console.log('Hub event', event);

      switch (event) {
        case 'signIn':
          cartMergeWithLocal(dispatch);
          SetUserProfileData();
          dispatch(GetFavouriteFromBackend(10, 1));
          break;
        case 'cognitoHostedUI':
          // getUser().then((userData) => setUser(userData));
          break;
        case 'signOut':
          // console.log('From cog signout');

          break;
        case 'oAuthSignOut':
          // ClearAllLocalData(dispatch);
          break;
        case 'signIn_failure':
          break;
        case 'cognitoHostedUI_failure':
          break;
        case 'customOAuthState':
          if (data && data?.search('PluginID_') !== -1) {
            const PluginId = localStorage.getItem('PluginID');
            getSessionToken().then((res) => {
              axios
                .post(`${baseUrl}/public/redirectSocialLogin`, {
                  pluginId: PluginId,
                  access_token: res.accessToken,
                  refresh_token: res.refreshToken,
                  id_token: res.idToken,
                })
                .then(() => {
                  localStorage.removeItem('PluginID');
                  window.location.href = `${process.env.NX_WEB_ROOT}/plugin/success.html`;
                })
                .catch(() => {
                  localStorage.removeItem('PluginID');
                  window.location.href = `${process.env.NX_WEB_ROOT}/plugin/failed.html`;
                });
            });
          }
          break;
      }
    });
  }, []);

  if (AFID && userRole === '') return null;
  return (
    <ThemeProvider theme={theme}>
      <LocalPlayerContext.Provider
        value={{
          LocalPlaying,
          LocalPlayingFrom,
          LocalHandlePlay,
          LocalHandlePause,
        }}
      >
        <OgvPlayerContext.Provider
          value={{
            OgvPlaying,
            OgvPlayingFrom,
            OgvPlayingIndex,
            OgvPlayerWaiting,
            OgvHandlePlay,
            OgvHandlePause,
          }}
        >
          <Routes />
        </OgvPlayerContext.Provider>
      </LocalPlayerContext.Provider>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: AppState): any => {
  return {
    user: state.user,
    email: state.user.email,
  };
};
export default connect(mapStateToProps, {})(App);

import { AdminUserActionType, SET_ADMIN_AFFILIATE_USERS_DATA } from '../types/actions';
import { AdminAffiliateUserState } from '../types/admin';

const affiliateUsersState: AdminAffiliateUserState = {
    data: [],
    loading: true
};

const adminAffiliateUsersReducer = (
    state = affiliateUsersState,
    action: AdminUserActionType
): AdminAffiliateUserState => {
    switch (action.type) {
        case SET_ADMIN_AFFILIATE_USERS_DATA:
            return { ...state, data: [...action.data], loading: false };
        default:
            return state;
    }
};

export { adminAffiliateUsersReducer };

import { CLEAR_FAVOURITE_DATA, CLEAR_PROFILE_DATA, CLEAR_USER } from '../store/types/actions';
import {
    PURCHASE_CART_ITEMS,
    UPDATE_CART_DATA,
    SET_ADMIN_PURCHASE_HISTORY_DATA
} from './../store/types/actions';

export const ClearAllLocalData = (dispatch) => {
    dispatch({ type: CLEAR_USER });
    dispatch({ type: CLEAR_PROFILE_DATA });
    dispatch({ data: [], type: PURCHASE_CART_ITEMS });
    dispatch({ data: [], type: UPDATE_CART_DATA });
    dispatch({ data: [], type: SET_ADMIN_PURCHASE_HISTORY_DATA });
    dispatch({ data: [], type: CLEAR_FAVOURITE_DATA });
};

import {User} from './User';
import {AdminUserData, AdminChangeStatusData, AdminAffiliateUser} from './admin';
import {ProfileData} from './profile';
import {SalesHistoryData} from './salesHistory';
import {PurchaseHistoryData} from './purchaseHistory';
import {CartItem} from './cart';
import {Packages} from './Packages';
import {SubscriptionHistoryData} from './subscriptionHistory';
import {AdminPurchaseHistoryData} from './adminPurchaseHistory';
import {AdminSubscriptionHistoryData} from './adminSubscriptionHistory';
import {Sounds} from './sounds';
import {AdminAffiliateHistoryData} from './adminAffiliateHistory';
import {FavouriteItem} from './Favourites';
import {AffiliateHistoryData} from './AffiliateHistory';
import {AffiliatePayoutHistoryData} from './affiliatePayoutHistory';
import {AdminAffiliatePayoutHistoryData} from './adminAffiliatePayoutHistory';
import {MatchHistoryData} from './matchHistory';
import {DragOutHistoryData} from './dragOutHistory';

// Register Types
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';

// Login Types
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';

// User Types
export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';

// Notification Types
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';

// error Messsage Types
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';

// Progress Types
export const PROGRESS_TOGGLE = 'PROGRESS_TOGGLE';
export const SET_FULL_LOADER_VISIBILITY = 'SET_FULL_LOADER_VISIBILITY';

// Admin Types
export const SET_USER_DATA = 'SET_USER_DATA';
export const CHANGE_USER_STATUS = 'CHANGE_USER_STATUS';
export const DELETE_USER = 'DELETE_USER';

// Profile Types
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const CLEAR_PROFILE_LOADING = 'CLEAR_PROFILE_LOADING';
export const CLEAR_PROFILE_DATA = 'CLEAR_PROFILE_DATA';
export const SET_PROFILE_EDIT = 'SET_PROFILE_EDIT';

// Sales History Types
export const SET_SALES_HISTORY_DATA = 'SET_SALES_HISTORY_DATA';

// Purchase History Types
export const SET_PURCHASE_HISTORY_DATA = 'SET_PURCHASE_HISTORY_DATA';
// Match History Types
export const SET_MATCH_HISTORY_DATA = 'SET_MATCH_HISTORY_DATA';
export const SET_DRAG_OUT_HISTORY_DATA = 'SET_DRAG_OUT_HISTORY_DATA';
// Subscription History Types
export const SET_SUBSCRIPTION_HISTORY_DATA = 'SET_SUBSCRIPTION_HISTORY_DATA';
export const SUBSCRIPTION_HISTORY_LOADING = 'SUBSCRIPTION_HISTORY_LOADING';
// Affiliate History Types
export const SET_AFFILIATE_HISTORY_DATA = 'SET_AFFILIATE_HISTORY_DATA';
export const SET_AFFILIATE_PAYOUT_DATA = 'SET_AFFILIATE_PAYOUT_DATA';
// subscriptions packages types
export const SET_PACKAGES = 'SET_PACKAGES';

export const SET_USER_VAT = 'SET_USER_VAT';

export const SET_VAT_LOADING = 'SET_VAT_LOADING';
//Favourites types
export const SET_FAVOURITES_DATA = 'SET_FAVOURITES_DATA';
export const ADD_FAVOURITES_DATA = 'ADD_FAVOURITES_DATA';
export const REMOVE_ONE_FAVOURITE_DATA = 'REMOVE_ONE_FAVOURITE_DATA';
export const FAVOURITE_DATA_LOADING_TOGGLE = 'FAVOURITE_DATA_LOADING_TOGGLE';
export const CLEAR_FAVOURITE_DATA = 'CLEAR_FAVOURITE_DATA';
// cart types
export const SET_CART_DATA = 'SET_CART_DATA';
// cart Loading bit
export const TOGGLE_CART_LOADING = 'TOGGLE_CART_LOADING';
// cart Update
export const UPDATE_CART_DATA = 'UPDATE_CART_DATA';
// cart remove one item by id
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
// Purchase cart item
export const PURCHASE_CART_ITEMS = 'PURCHASE_CART_ITEMS';
// Filter Already purchased loop from cart
export const FILTER_PURCHASED_CART_ITEMS = 'FILTER_PURCHASED_CART_ITEMS';
// ERROR Sales History Types
export const ERROR_SALES_HISTORY_DATA = 'ERROR_SALES_HISTORY_DATA';

// Error purchase history
export const ERROR_PURCHASE_HISTORY_DATA = 'ERROR_PURCHASE_HISTORY_DATA';

// Purchase History Types for admin
export const SET_ADMIN_PURCHASE_HISTORY_DATA = 'SET_ADMIN_PURCHASE_HISTORY_DATA';

// Affiliate History Type for admin
export const SET_ADMIN_AFFILIATE_HISTORY_DATA = 'SET_ADMIN_AFFILIATE_HISTORY_DATA';
export const SET_ADMIN_AFFILIATE_PAYOUT_HISTORY_DATA = 'SET_ADMIN_AFFILIATE_PAYOUT_HISTORY_DATA';

// Subscription History Types for admin
export const SET_ADMIN_SUBSCRIPTION_HISTORY_DATA = 'SET_ADMIN_SUBSCRIPTION_HISTORY_DATA';

// Sounds
export const SET_ALL_SOUNDS = 'SET_ALL_SOUNDS';
export const ALL_SOUNDS_TOGGLE_LOADING = 'ALL_SOUNDS_TOGGLE_LOADING';
export const DRAG_OUT_SOUNDS_TOGGLE_LOADING = 'DRAG_OUT_SOUNDS_TOGGLE_LOADING';

// Admin Affiliate Users
export const SET_ADMIN_AFFILIATE_USERS_DATA = 'SET_ADMIN_AFFILIATE_USERS_DATA';

// INTERFACES --------------------------------->

export interface GetAllSoundsAction {
  type: typeof SET_ALL_SOUNDS;
  data: Sounds[];
}
export interface AllSoundToggleLoading {
  type: typeof ALL_SOUNDS_TOGGLE_LOADING;
  loading: boolean;
}
export interface DragOutSoundToggleLoading {
  type: typeof DRAG_OUT_SOUNDS_TOGGLE_LOADING;
  loading: boolean;
}
export interface SetUserAction {
  type: typeof SET_USER;
  user: User;
}
export interface ClearUserAction {
  type: typeof CLEAR_USER;
}

export interface SetNotification {
  payload: {
    message: string;
    status: string;
  };
  type: typeof SHOW_NOTIFICATION;
}

// error message
export interface SetErrorMessage {
  payload: {
    message: string;
  };
  type: typeof SHOW_ERROR_MESSAGE;
}
// Progress interface
export interface ProgressToggle {
  payload: boolean;
  type: typeof PROGRESS_TOGGLE;
}
export interface ToggleFullLoader {
  payload: boolean;
  type: typeof SET_FULL_LOADER_VISIBILITY;
}

// Admin
export interface SetAdminUserData {
  data: AdminUserData[];
  type: typeof SET_USER_DATA;
}

export interface ChangeUserStatus {
  payload: AdminChangeStatusData;
  type: typeof CHANGE_USER_STATUS;
}

export interface SetDeleteUser {
  payload: string[];
  type: typeof DELETE_USER;
}

// Profile
export interface SetProfileData {
  data: ProfileData[];
  type: typeof SET_PROFILE_DATA;
}
export interface ClearProfileAction {
  type: typeof CLEAR_PROFILE_DATA;
}
export interface SetProfileEditAction {
  type: typeof SET_PROFILE_EDIT;
  payload: boolean;
}
export interface ClearProfileLoading {
  type: typeof CLEAR_PROFILE_LOADING;
}

// Sales History
export interface SetSalesHistory {
  data: SalesHistoryData[];
  type: typeof SET_SALES_HISTORY_DATA;
}
// Purchase History
export interface SetPurchaseHistory {
  data: PurchaseHistoryData[];
  type: typeof SET_PURCHASE_HISTORY_DATA;
}
export interface SetMatchHistory {
  data: MatchHistoryData[];
  type: typeof SET_MATCH_HISTORY_DATA;
}
export interface SetDragOutHistory {
  data: DragOutHistoryData[];
  type: typeof SET_DRAG_OUT_HISTORY_DATA;
}
//FAVORITES
export interface SetFavouritesDataAction {
  data: FavouriteItem[];
  type: typeof SET_FAVOURITES_DATA;
}
export interface UpdateFavouritesDataAction {
  data: FavouriteItem;
  type: typeof ADD_FAVOURITES_DATA;
}
export interface ClearFavouritesDataAction {
  type: typeof CLEAR_FAVOURITE_DATA;
}
export interface RemoveOneFavoriteDataAction {
  LoopId: string;
  type: typeof REMOVE_ONE_FAVOURITE_DATA;
}
export interface FavoriteLoadingToggle {
  loading: boolean;
  type: typeof FAVOURITE_DATA_LOADING_TOGGLE;
}
// Affiliate actions
export interface SetAffiliateHistoryDataAction {
  data: AffiliateHistoryData[];
  type: typeof SET_AFFILIATE_HISTORY_DATA;
}

export interface SetAffiliatePayoutHistoryAction {
  data: AffiliatePayoutHistoryData[];
  type: typeof SET_AFFILIATE_PAYOUT_DATA;
}
// cart
export interface SetCartDataAction {
  data: CartItem;
  type: typeof SET_CART_DATA;
}
export interface UpdateCartDataAction {
  data: CartItem[];
  type: typeof UPDATE_CART_DATA;
}
export interface CartRemoveItemAction {
  id: string;
  type: typeof CART_REMOVE_ITEM;
}
export interface CartLoadingToggle {
  payload: boolean;
  type: typeof TOGGLE_CART_LOADING;
}
export interface PurchaseCartItems {
  data: CartItem[];
  type: typeof PURCHASE_CART_ITEMS;
}

export interface FilterPurchasedCart {
  data: PurchaseHistoryData[];
  type: typeof FILTER_PURCHASED_CART_ITEMS;
}

// Error Sales History
export interface ErrorSalesHistory {
  type: typeof ERROR_SALES_HISTORY_DATA;
}
// Error Purchase History
export interface ErrorPurchaseHistory {
  type: typeof ERROR_PURCHASE_HISTORY_DATA;
}

// Set Packages
export interface SetSubscriptionPackagesAction {
  payload: Packages[];
  type: typeof SET_PACKAGES;
}

export interface SetUserVatAction {
  payload: {
    vat: number;
    stripeTaxId: string;
    vatCountry: string;
  };
  type: typeof SET_USER_VAT;
}
export interface SetVatLoadingAction {
  payload: boolean;
  type: typeof SET_VAT_LOADING;
}
// Subscription History
export interface SetSubscriptionHistory {
  data: SubscriptionHistoryData[];
  type: typeof SET_SUBSCRIPTION_HISTORY_DATA;
}
export interface SubscriptionHistoryLoadingAction {
  payload: boolean;
  type: typeof SUBSCRIPTION_HISTORY_LOADING;
}
// Purchase History
export interface SetAdminPurchaseHistory {
  data: AdminPurchaseHistoryData[];
  type: typeof SET_ADMIN_PURCHASE_HISTORY_DATA;
}

export interface SetAdminSubscriptionHistory {
  data: AdminSubscriptionHistoryData[];
  type: typeof SET_ADMIN_SUBSCRIPTION_HISTORY_DATA;
}

export interface SetAdminAffiliateUsersAction {
  data: AdminAffiliateUser[];
  type: typeof SET_ADMIN_AFFILIATE_USERS_DATA;
}
export interface SetAdminAffiliatePayoutAction {
  data: AdminAffiliatePayoutHistoryData[];
  type: typeof SET_ADMIN_AFFILIATE_PAYOUT_HISTORY_DATA;
}

export interface SetAdminAffiliateHistory {
  data: AdminAffiliateHistoryData[];
  type: typeof SET_ADMIN_AFFILIATE_HISTORY_DATA;
}

// TYPES -->
export type ErrorMessageType = SetErrorMessage | ProgressToggle;
export type NotificationType = SetNotification | ProgressToggle | ToggleFullLoader;
export type UserActionType = SetUserAction | ClearUserAction;
export type AdminUserActionType =
  | SetAdminUserData
  | ChangeUserStatus
  | SetDeleteUser
  | SetAdminPurchaseHistory
  | SetAdminSubscriptionHistory
  | SetAdminAffiliateHistory
  | SetAdminAffiliateUsersAction
  | SetAdminAffiliatePayoutAction;
export type ProfileActionType =
  | SetProfileData
  | ClearProfileAction
  | ClearProfileLoading
  | SetProfileEditAction;
export type HistoryActionType =
  | SetSalesHistory
  | SetPurchaseHistory
  | SetSubscriptionHistory
  | SubscriptionHistoryLoadingAction
  | SetMatchHistory
  | SetDragOutHistory
  | DragOutSoundToggleLoading
  | ErrorSalesHistory
  | ErrorPurchaseHistory;
export type CartActionType =
  | SetCartDataAction
  | UpdateCartDataAction
  | PurchaseCartItems
  | CartLoadingToggle
  | CartRemoveItemAction
  | FilterPurchasedCart;
export type SubscriptionsType =
  | SetSubscriptionPackagesAction
  | SetUserVatAction
  | SetVatLoadingAction;
export type AffiliateHistoryType = SetAffiliateHistoryDataAction | SetAffiliatePayoutHistoryAction;

export type SoundsActionType = GetAllSoundsAction | AllSoundToggleLoading;
export type FavouritesActionType =
  | SetFavouritesDataAction
  | UpdateFavouritesDataAction
  | RemoveOneFavoriteDataAction
  | FavoriteLoadingToggle
  | ClearFavouritesDataAction;
export type AppActions =
  | UserActionType
  | NotificationType
  | ErrorMessageType
  | AdminUserActionType
  | ProfileActionType
  | HistoryActionType
  | AffiliateHistoryType
  | CartActionType
  | SubscriptionsType
  | SoundsActionType
  | FavouritesActionType;

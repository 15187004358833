/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
// console.log(`${process.env.NX_WEB_ROOT}/`)
const awsmobile = {
  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id:
    'eu-central-1:df5a3f3a-47c6-43e8-b51e-ca636f943f49',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_eW1zmt6Na',
  aws_user_pools_web_client_id: '5gogafuie476cs6s1psrqs3jv',
  oauth: {
    domain:
      'jamahookdevfa71e1ad-fa71e1ad-dev.auth.eu-central-1.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    // redirectSignIn: 'https://dev.vst.jamahook.com/',
    // redirectSignIn: 'https://beta.vst.jamahook.com/',
    // redirectSignIn: 'http://localhost:4200/',
    redirectSignIn: `${process.env.NX_WEB_ROOT}/`,
    // redirectSignOut: 'https://dev.vst.jamahook.com/',
    // redirectSignOut: 'https://beta.vst.jamahook.com/',
    // redirectSignOut: 'http://localhost:4200/',
    redirectSignOut: `${process.env.NX_WEB_ROOT}/`,
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
};

export const awsmobileProd = {
  aws_project_region: 'eu-central-1',
  aws_cognito_identity_pool_id:
    'eu-central-1:8b485bf4-6fe6-4238-888d-a9bb99d45439',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_5NsNEKWYU',
  aws_user_pools_web_client_id: '6255tmvtg59re06pp59cleblau',
  oauth: {
    domain: 'cognito.vst.jamahook.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    // redirectSignIn: 'https://vst.jamahook.com/',
    // redirectSignIn: 'https://beta.vst.jamahook.com/',
    // redirectSignIn: 'http://localhost:4200/',
    redirectSignIn: `${process.env.NX_WEB_ROOT}/`,
    // redirectSignOut: 'https://vst.jamahook.com/',
    // redirectSignOut: 'https://beta.vst.jamahook.com/',
    // redirectSignOut: 'http://localhost:4200/',
    redirectSignOut: `${process.env.NX_WEB_ROOT}/`,
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
};

export default awsmobile;

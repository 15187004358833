export default {
    values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        asd: 1440,
        xl: 1920
    }
};

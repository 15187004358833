import {
  NotificationType,
  PROGRESS_TOGGLE,
  SET_FULL_LOADER_VISIBILITY,
} from '../types/actions';
import { Notification } from '../types/Notification';

const notifiactionReducerState: Notification = {
  message: '',
  status: '',
  progress: false,
  fullLoader: false,
};

const notifiactionReducer = (
  state = notifiactionReducerState,
  action: NotificationType
): Notification => {
  switch (action.type) {
    case 'SHOW_NOTIFICATION':
      // console.log(action.payload);
      return { ...state, ...action.payload };
    case 'PROGRESS_TOGGLE':
      return { ...state, progress: action.payload };
    case SET_FULL_LOADER_VISIBILITY:
      return { ...state, fullLoader: action.payload };
    default:
      return state;
  }
};
export { notifiactionReducer };

import {IconButtonClasses, Theme} from '@mui/material';
import {OverridesStyleRules} from '@mui/material/styles/overrides';

export default {
  styleOverrides: {
    root: ({theme}) => ({
      color: theme.palette.defaults.white,
      '&.Mui-disabled': {
        color: theme.palette.grey[600],
      },
    }),
    colorPrimary: ({theme}) => ({
      color: theme.palette.primary.main,
    }),
  } as Partial<
    OverridesStyleRules<keyof IconButtonClasses, 'MuiIconButton', Omit<Theme, 'components'>>
  >,
};

import axios from 'axios';
export const RegisterToPlugin = (body, baseUrl) => {
    axios
        .post(`${baseUrl}/auth/updateUser`, body, {
            headers: {
                token: 'U2FsdGVkX1+/iRNdrBHQ+QxJHcBXhnsEOpgMIPbGC+THD8BWOVIBMreI+nUi4c56'
            }
        })
        .catch((err) => {
            console.log(err);
        });
};

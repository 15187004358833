import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      fontSize: '0.75rem',
      color: palette.typography.infoLight,
    },
  },
};
